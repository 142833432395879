export default (stateFields: any) => {
  const topLevelFieldKeys = Object.keys(stateFields);
  const topLevelSchema: { [key: string]: any } = {};

  let currentRef = null;
  topLevelFieldKeys.forEach((topLevelKey) => {
    const nestedKeys = topLevelKey.split('.');
    currentRef = topLevelSchema;

    for (let i = 0; i < nestedKeys.length; i += 1) {
      const nestedKey = nestedKeys[i];
      const isLastKey = i + 1 === nestedKeys.length;

      if (!currentRef[nestedKey] && isLastKey) {
        currentRef[nestedKey] = stateFields[topLevelKey].value;
      } else if (!currentRef[nestedKey] && !isLastKey) {
        currentRef[nestedKey] = {};
      }
      currentRef = currentRef[nestedKey];
    }
  });

  // console.log('topLevelSchema: ', topLevelSchema);
  return topLevelSchema;
};
