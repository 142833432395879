import { stringify } from 'query-string';
import ApiService from './service';
import * as http from './http';
import { CrudListRequestModel } from '../models';

const serviceEndpoint = 'notification/v1/user-notification';

export default class NotificationService extends ApiService {
  public async getNotificationList(request: CrudListRequestModel) {
    const { page = 1, perPage = 10 } = request.pagination;
    const query = {
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${this.apiDomain}/${serviceEndpoint}/messages?${stringify(
      query
    )}`;
    return http.get(url, this.token);
  }

  public async getUnreadCount() {
    const url = `${this.apiDomain}/${serviceEndpoint}/messages/unread-count`;
    return http.get(url, this.token);
  }

  public async markAllRead() {
    const url = `${this.apiDomain}/${serviceEndpoint}/messages/mark-all-read`;
    return http.put(url, {}, this.token);
  }
}
