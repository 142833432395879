import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './PromoCode.scss';

interface CustomProps {
  promotion?: any;
  amount?: number;
  removeButtonText?: string;
  showRemoveButton?: boolean;
  onChange: () => void;
  showPromoCodeBox?: boolean;
}

class PromoCode extends Component<CustomProps> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onChange();
  }
  renderPromoCodeText() {
    return (
      <div className="ApplyCoupon__text">
        <span className="ApplyCoupon__title">
          {this.props.promotion
            ? this.props.promotion.publicPromoCode
            : 'PROMOTION'}
        </span>
        {this.props.amount !== 0 && (
          <div className="ApplyCoupon__subtitle">AED {this.props.amount?.toFixed(2)}</div>
        )}
      </div>
    );
  }

  renderRemoveButton() {
    return (
      <div className="ApplyCoupon__btn">
        <button
          type="button"
          className="Button Button__link"
          onClick={() => this.onClick()}
        >
          {this.props.promotion ? (this.props.removeButtonText || 'Remove') : 'Apply'}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="ApplyCoupon">
        <div className="ApplyCoupon__content">
          <div className="ApplyCoupon__icon">
            <span className="icon-promocode">
              <span className="path1" />
              <span className="path2" />
            </span>
          </div>
          {this.renderPromoCodeText()}
        </div>
        {this.props.showRemoveButton && this.renderRemoveButton()}
      </div>
    );
  }
}

// PromoCode.propTypes = {
//   promotion: PropTypes.shape({
//     publicPromoCode: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//   }).isRequired,
//   amount: PropTypes.number,
//   removeButtonText: PropTypes.string,
//   showRemoveButton: PropTypes.bool,
//   // selected: PropTypes.bool.isRequired,
// };

// PromoCode.defaultProps = {
//   removeButtonText: 'Remove',
//   showRemoveButton: true,
//   amount: 0,
// };

export default PromoCode;
