import axios from 'axios';
import api from '.';

const ignoreOn = (err: any) => {
  if (
    err &&
    err.response &&
    err.response.config &&
    err.response.config.url === api.user.loginUrl
  ) {
    return true;
  }
  return false;
};

const useUnauthorizedApiResponseInterceptor = (
  onUnauthorizedResponse?: () => void
) => {
  axios.interceptors.response.use(undefined, (err) => {
    if (err.response && err.response.status === 401) {
      if (onUnauthorizedResponse && !ignoreOn(err)) {
        onUnauthorizedResponse();
      }
    }
    throw err;
  });
};

export default useUnauthorizedApiResponseInterceptor;
