import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import api from '../../api';
import { types as commonTypes } from './common';
import { StudentCourseStage } from '../../models/user';

// action types
export const types = {
  // EXAM
  GET_EXAM_REQUEST: 'INTEGRATOR/GET_EXAM_REQUEST',
  GET_EXAM_SUCCESS: 'INTEGRATOR/GET_EXAM_SUCCESS',
  GET_EXAM_ERROR: 'INTEGRATOR/GET_EXAM_ERROR',
};

export interface IntegratorState {
  examList: {
    isLoading: boolean;
    exams?: StudentCourseStage[];
    isLoaded: boolean;
  };
}

// initial state
const initialState: IntegratorState = {
  examList: {
    isLoading: false,
    exams: undefined,
    isLoaded: false,
  },
};

// reducer
export default (state = initialState, action: any): IntegratorState => {
  switch (action.type) {
    case commonTypes.RESET_DATA:
      return {
        ...initialState,
      };

    // details
    case types.GET_EXAM_REQUEST:
      return {
        ...state,
        examList: {
          ...state.examList,
          isLoading: true,
          isLoaded: false,
        },
      };
    case types.GET_EXAM_SUCCESS:
      return {
        ...state,
        examList: {
          ...state.examList,
          isLoading: false,
          isLoaded: true,
          exams: action.data || [],
        },
      };
    case types.GET_EXAM_ERROR:
      return {
        ...state,
        examList: {
          ...state.examList,
          isLoading: false,
          isLoaded: true,
        },
      };

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  getExamList: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.GET_EXAM_REQUEST });
    try {
      const response = await api.user.getExamList();
      dispatch({
        type: types.GET_EXAM_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      dispatch({ type: types.GET_EXAM_ERROR });
      // serializeAndShowFormErrors(error);
      // throw error;
    }
  },
};

export function serializeAndShowFormErrors(error: any) {
  if (error.response && error.response.data && error.response.data.errors) {
    const { errors } = error.response.data;
    Object.keys(errors).forEach((e) => {
      // console.log('error: ', e, errors[e].message);
      toastr.error(errors[e].message, '');
    });
    return;
  }
  toastr.error('Error', 'Invalid Data in Form!');
}
