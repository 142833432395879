import { MessageType } from '../models/messages';
import qs from 'query-string';
import { PaymentMessage } from '../models/payment';

export const createPaymentResponseMessage = (
  location: Location
): PaymentMessage => {
  return {
    type: MessageType.PaymentInformation,
    data: qs.parse(location.search) as { ref?: string },
  };
};
