import React, { useEffect, useCallback } from 'react';
import { PaymentIFrameProps } from './props';

import ReactIFrame from 'react-iframe';
import { MessageType } from '../../models/messages';

const PaymentIFrame: React.SFC<PaymentIFrameProps> = (
  props: PaymentIFrameProps
) => {
  const onMessage = useCallback(
    (message: MessageEvent) => {
      if (
        message.data &&
        message.data.type &&
        message.data.type === MessageType.PaymentInformation &&
        props.onCompletePayment
      ) {
        props.onCompletePayment(message.data);
      }
    },
    [props]
  );
  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);
  return <ReactIFrame {...props} target="_top" />;
};

export default PaymentIFrame;
