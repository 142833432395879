import { MultiLanguageText } from '../models/text';

export const formatMultiLanguageText = (
  text: MultiLanguageText | string | undefined
): string | undefined => {
  if (text === undefined || text === null) {
    return text;
  }
  if (typeof text === 'string') {
    return text;
  }
  if (text && text.en) {
    return text.en;
  }
  return '';
};
