import React from 'react';
// import PropTypes from 'prop-types';

import './ApplyPromoCode.scss';
import Button from '../button';

import { TextInput } from '../form-group';

interface CustomProps {
  onApply: (value: string) => void;

  promoCode?: string;
}

interface CustomState {
  value: string;
}

class ApplyPromoCode extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {};

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: '',
    };
    this.onApply = this.onApply.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onApply() {
    const promoCode = this.state.value;
    this.props.onApply(promoCode);
  }

  onChange(value: string) {
    this.setState({ value });
  }

  render() {
    return (
      <div className="ApplyPromoCode">
        <TextInput
          placeholder="Enter Promo code"
          label="Promocode"
          onChange={this.onChange}
        ></TextInput>
        <Button onClick={this.onApply}>Apply</Button>
      </div>
    );
  }
}

export default ApplyPromoCode;
