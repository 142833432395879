import React from 'react';
// import PropTypes from 'prop-types';

import './PromoCode.scss';

interface CustomProps {
  onApply?: (value: string) => void;
  onClose?: (value: string) => void;
  promoCode?: string;
}

interface CustomState {
  value: string;
}

class PromoCode extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {};

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: '',
    };
  }

  render() {
    return (
      <div className="PromoCode">
        <div className="PromoCode-inner">
          <span className="PromoCode__code">{this.props.promoCode}</span>
        </div>
      </div>
    );
  }
}

export default PromoCode;
