export const trackFbConversion = (action: string) => {
  const w = window as any;
  if (w.fbq) {
    w.fbq('track', action);
  }
};

export const trackFbPageView = () => {
  trackFbConversion('PageView');
};

export const trackFbLead = () => {
  trackFbConversion('Lead');
};
