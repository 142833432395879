import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AccordionBlockCustomeProps } from './props';

const AccordionBlock = (props: AccordionBlockCustomeProps) => {
  const ref = useRef(null);

  const {
    children,
    isCurrent,
    isTouched,
    title,
    icon,
    actionText,
    summaries,
    gotoNextBlock,
    addSummary,
    overrideTick,
    showTick,
    suppressParentProps,
    gotoPreviousBlock,
    showAll,
  } = props;

  const parentProps = {
    gotoNextBlock,
    gotoPreviousBlock,
    addSummary,
  };

  function handleClick() {
    if (props.openBlock) props.openBlock(ref);
  }

  function renderActionText() {
    const text = actionText || '';
    const summaryList = summaries || {};
    if (!isCurrent && Object.keys(summaryList).length) {
      return (
        <div className="summary">
          {Object.keys(summaryList).map((summaryKey) => {
            return (
              <>
                {summaryList[summaryKey].label}
                <span className="summary__item-type">
                  {summaryList[summaryKey].value}
                </span>
              </>
            );
          })}
        </div>
      );
    }
    return <span>{text}</span>;
  }

  const borderClasses = `${
    props.noBorder ? '' : 'AccordionBlocks__item--border'
  }`;

  const tickRender = overrideTick
    ? showTick
      ? 'active'
      : 'inactive'
    : isCurrent
    ? 'inactive'
    : isTouched
    ? 'active'
    : 'inactive';

  const touchedClass = isTouched ? 'active' : '';
  const classes = props.className ? props.className : '';

  return (
    <div
      ref={ref}
      key={title}
      className={`AccordionBlocks__item AccordionBlocks__item--boxshadow ${borderClasses} ${touchedClass} ${classes}`}
    >
      <div
        className={`AccordionBlocks__heading ${
          props.headSize ? 'AccordionBlocks__heading--' + props.headSize : ''
        }`}
        role="presentation"
        onClick={handleClick}
      >
        <div className="AccordionBlocks__heading-left">
          {icon && (
            <div className="AccordionBlocks__heading-icon">
              <img src={icon} alt={title} width="28" />
            </div>
          )}
          <div className="AccordionBlocks__head">
            <h6 className="AccordionBlocks__title AccordionBlocks__title--bold">
              {title}
            </h6>
            {(actionText || Object.keys(summaries || {}).length > 0) && (
              <div className="AccordionBlocks__actionText">
                {renderActionText()}
              </div>
            )}
          </div>
        </div>
        <span className={`tick ${tickRender}`}>
          <i className="icon-tick" />
        </span>
      </div>
      <div
        className={`AccordionBlocks__collapse ${
          isCurrent || showAll ? 'AccordionBlocks__collapse--in' : ''
        }`}
      >
        <div
          className={`AccordionBlocks__body ${
            props.bodySize ? 'AccordionBlocks__body--' + props.bodySize : ''
          }`}
        >
          {suppressParentProps
            ? children
            : React.cloneElement(children, parentProps)}
        </div>
      </div>
    </div>
  );
};

AccordionBlock.propTypes = {
  showAll: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isTouched: PropTypes.bool,

  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  actionText: PropTypes.string.isRequired,

  children: PropTypes.element.isRequired,
  summaries: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  // events
  openBlock: PropTypes.func,
  gotoNextBlock: PropTypes.func,
  gotoPreviousBlock: PropTypes.func,
  addSummary: PropTypes.func,
};

// many of the props are coming from parent and is required for proper functioning
// but since the child will get rendered before parent, we are giving a default value for them.
AccordionBlock.defaultProps = {
  isCurrent: false,
  isTouched: false,
  icon: undefined,

  summaries: {},
  openBlock: () => {
    return;
  },
  gotoNextBlock: () => {
    return;
  },
  gotoPreviousBlock: () => {
    return;
  },
  addSummary: () => {
    return;
  },
};

export default AccordionBlock;
