import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import SelectInput from '../SelectInput';
import TextInput from '../text-input';

import '../Input.scss';
import './SelectTextInput.scss';

interface CustomProps {
  data: any[];
  optionLabel?: string;
  optionValue?: string;
  onChange: (value: any) => void;
  error?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  type?: 'text' | 'number';
  gap?: 'sm' | 'md' | 'lg';
  defaultSelected?: string;
  defaultEntered?: string | number;
  zIndex?: number;
  width?: {
    select?: number;
    text?: number;
  };
  enableSelectSearch?: boolean;
  disableSelected?: boolean;
  disableNumberScrolls?: boolean;
}

interface CustomState {
  value: {
    selected?: string | number;
    entered?: string | number;
  };
}

class SelectTextInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    placeholder: '',
    error: '',
    name: '',
    label: '',
    optionLabel: 'label',
    optionValue: 'value',
    type: 'text',
    gap: 'md',
    defaultSelected: '',
    defaultEntered: '',
    zIndex: 1,
    width: { select: 22, text: 78 },
    enableSelectSearch: false,
    disableSelected: false,
    disableNumberScrolls: false,
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: {
        selected: props.defaultSelected,
        entered: props.defaultEntered,
      },
    };
  }

  handleChange(field: 'selected' | 'entered', fieldValue: string | number) {
    if (field === 'selected') {
      this.setState(
        (prevState: CustomState): CustomState => {
          const value = {
            selected: fieldValue,
            entered: prevState.value.entered,
          };
          this.props.onChange(value);
          return {
            ...prevState,
            value,
          };
        }
      );
    }
    if (field === 'entered') {
      this.setState((prevState) => {
        const value = {
          selected: prevState.value.selected,
          entered: fieldValue,
        };
        this.props.onChange(value);
        return { value };
      });
    }
  }

  render() {
    const {
      data,
      optionLabel,
      optionValue,
      error,
      name,
      label,
      placeholder,

      gap,
      type,
      zIndex,
      width,
      enableSelectSearch,
    } = this.props;
    const selectProps = { data, optionLabel, optionValue };
    const textInputProps = { placeholder, type };
    const inputIdCandidate = name || label || placeholder;
    const inputId = inputIdCandidate;

    return (
      <div className={`Input Input--gap-${gap}`}>
        {label && (
          <label className="Input__label" htmlFor={inputId}>
            {label}
          </label>
        )}
        <div className="InputGroup">
          <SelectInput
            {...selectProps}
            default={this.props.defaultSelected}
            onChange={(val: string | number) =>
              this.handleChange('selected', val)
            }
            stickyStyles={{
              position: 'absolute',
              width: `${(width && width.select) || 100}%`,
              zIndex: `${zIndex}`,
            }}
            isSearchable={enableSelectSearch}
            disabled={this.props.disableSelected}
          />
          <TextInput
            {...textInputProps}
            onChange={(val: string | number) =>
              this.handleChange('entered', val)
            }
            stickyStyles={{
              position: 'relative',
              paddingLeft: `${((width && width.select) || 0.5) - 0.5}%`,
              width: `${(width && width.text) || 100}%`,
            }}
            disableNumberScrolls={this.props.disableNumberScrolls}
            default={this.props.defaultEntered}
          />
        </div>
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// SelectTextInput.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   optionLabel: PropTypes.string,
//   optionValue: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   error: PropTypes.string,
//   name: PropTypes.string,
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
//   type: PropTypes.oneOf(['text', 'number']),
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   defaultSelected: PropTypes.string,
//   defaultEntered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   zIndex: PropTypes.number,
//   width: PropTypes.shape({
//     select: PropTypes.number.isRequired,
//     text: PropTypes.number.isRequired,
//   }),
//   enableSelectSearch: PropTypes.bool,
//   disableSelected: PropTypes.bool,
//   disableNumberScrolls: PropTypes.bool,
// };

// SelectTextInput.defaultProps = {
//   placeholder: '',
//   error: '',
//   name: '',
//   label: '',
//   optionLabel: 'label',
//   optionValue: 'value',
//   type: 'text',
//   gap: 'md',
//   defaultSelected: '',
//   defaultEntered: '',
//   zIndex: 1,
//   width: { select: 20, text: 80 },
//   enableSelectSearch: false,
//   disableSelected: false,
//   disableNumberScrolls: false,
// };

export default SelectTextInput;
