import moment from 'moment';
import 'moment-timezone';
import { Time } from '../models/schedule';

const DubaiTimezone = 'Asia/Dubai';

export const formatDate = (date: string | Date, dateFormat: string): string => {
  return moment(date).format(dateFormat);
};
export const formatUTCDate = (
  date: string | Date,
  dateFormat: string
): string => {
  return moment(date)
    .utc()
    .format(dateFormat);
};

export const convertToTimezone = (
  date: Date,
  timezone: string = DubaiTimezone
) => {
  return moment(date)
    .tz(timezone)
    .toDate();
};

export const formatDateWithTimeZone = (
  date: string | Date,
  timezone: string = DubaiTimezone,
  dateFormat: string = DateFormats.NormalFromat
) => {
  return moment(date)
    .tz(timezone)
    .format(dateFormat);
};

export const tryFormatDate = (
  date: string | Date,
  dateFormat: string = DateFormats.NormalFromat
): string | Date => {
  try {
    return formatDate(date, dateFormat);
  } catch (e) {
    return date;
  }
};

export const tryFormatDateWithTimezone = (
  date: string | Date,
  timezone: string = DubaiTimezone,
  dateFormat: string = DateFormats.NormalFromat
): string | Date => {
  try {
    return formatDateWithTimeZone(date, timezone, dateFormat);
  } catch (e) {
    return date;
  }
};

export class DateFormats {
  static NormalFromat = 'DD-MM-YYYY h:mm A';
  static MonthNameFormat = 'DD-MMM-YYYY';
  static MonthNameOnly = 'MMM';
  static TimeOnlyFormat = 'h:mm A';
}

export const addDays = (date: Date, days: number) => {
  return moment(date)
    .add(days, 'days')
    .toDate();
};

export const formatTime = (time: Time, railwayTime?: boolean) => {
  let { hour } = time;
  let amPart: string = '';
  if (!railwayTime) {
    amPart = ' AM';
    if (hour >= 12) {
      if (hour > 12) {
        hour = hour - 12;
      }
      amPart = ' PM';
    }
  }
  const hourPart = `${Math.floor(hour / 10) ? '' : '0'}${hour}`;
  const minutesPart = `${Math.floor(time.minute / 10) ? '' : '0'}${
    time.minute
  }`;
  return `${hourPart}:${minutesPart}${amPart}`;
};

export const isTimeMatching = (time1: Time, time2: Time) => {
  if (time1 === time2) {
    return true;
  }
  if (!time1 || !time2) {
    return false;
  }
  return time1.hour === time2.hour && time1.minute === time2.minute;
};

export const getUTCStartOfDate = (date: Date): Date => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};
