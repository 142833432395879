import { useEffect } from 'react';
import { Dict } from '../models/interfaces';
import { trackGtagConversion } from '../utils/gtag';

export const useGTagConversionTracker = (
  options: Dict = {},
  action: string = 'conversion'
) => {
  useEffect(() => {
    trackGtagConversion(options, action);
  }, []);
};
