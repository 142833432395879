import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'upload/v1';

export default class MediaService extends ApiService {
  public async upload(
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ) {
    const formData = new FormData();
    formData.append('uploadFile', file);
    const url = `${this.apiDomain}/${serviceEndpoint}/upload`;
    return http.upload(url, formData, onUploadProgress, this.token);
  }

  public async getVideoThumb(videoUri: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/get-video-thumb/?path=${videoUri}`;
    return http.get(url, this.token);
  }
  public async getSignedDocumentUrl(
    docFilepath: string,
    overrideToken?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/get-signed-url?filepath=${docFilepath}`;
    return http.get(url, overrideToken || this.token);
  }
}
