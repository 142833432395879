import React from 'react';
import { Dict } from '../../models/interfaces';
import { useGAPageViewTracker } from '../../hooks/google-analytics';
import { useFbPageViewTracker } from '../../hooks/fb-analytics';

export const withTracker = (WrappedComponent: any, options: Dict = {}) => {
  const HOC = (props: any) => {
    useGAPageViewTracker();
    useFbPageViewTracker();

    // tslint:disable-next-line: no-object-literal-type-assertion
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    return <WrappedComponent {...props} />;
  };

  return HOC;
};
