import fooBar from './fooBar';
import fooBaz from './fooBaz';
import { Dict } from '../../models/interfaces';
import { NotificationEventTypes } from '../eventTypes';

const notificationRegistry: Dict = {
  [NotificationEventTypes.FooBar]: fooBar,
  [NotificationEventTypes.FooBaz]: fooBaz,
};

export default notificationRegistry;
