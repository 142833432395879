import {
  CrudService,
  MediaService,
  RegistrationService,
  UserService,
  PaymentService,
  LectureService,
  IntegratorService,
  ScheduleService,
  EntityService,
  NotificationService,
  CurriculumService,
} from './services';
import ApiServiceDataStore from './services/data';

export default class EdsApiClient {
  public crud: CrudService;
  public user: UserService;
  public registration: RegistrationService;
  public media: MediaService;
  public payment: PaymentService;
  public lecture: LectureService;
  public integrator: IntegratorService;
  public schedule: ScheduleService;
  public entity: EntityService;
  public notification: NotificationService;
  public curriculum: CurriculumService;
  private store: ApiServiceDataStore = {
    apiDomain: '',
    token: undefined,
  };

  constructor(apiDomain: string) {
    this.store.apiDomain = apiDomain;
    this.crud = new CrudService(this.store);
    this.user = new UserService(this.store);
    this.registration = new RegistrationService(this.store);
    this.media = new MediaService(this.store);
    this.payment = new PaymentService(this.store);
    this.lecture = new LectureService(this.store);
    this.integrator = new IntegratorService(this.store);
    this.schedule = new ScheduleService(this.store);
    this.entity = new EntityService(this.store);
    this.notification = new NotificationService(this.store);
    this.curriculum = new CurriculumService(this.store);
  }

  public setToken(token?: string) {
    this.store.token = token;
  }
}
