function sanitizeChildren(children: any) {
  let sanitizedChildren = [];
  if (!Array.isArray(children)) {
    sanitizedChildren.push(children);
  } else {
    sanitizedChildren = children;
  }
  return sanitizedChildren;
}

export default sanitizeChildren;
