import ReactGA from 'react-ga';
import { Dict } from '../models/interfaces';
export const trackPageView = (page: string, options: Dict = {}) => {
  ReactGA.set({ page, ...options });
  ReactGA.pageview(page);
};

export const trackConversion = (category: string, options: Dict = {}) => {
  ReactGA.event({
    category,
    action: 'conversion',
  });
};
