import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'integrator/v1';

export default class IntegratorService extends ApiService {
  public async getExamList(trafficFileNo: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/getStudentExamList/${trafficFileNo}`;
    return http.get(url, this.token);
  }
}
