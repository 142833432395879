import * as http from './http';
import ApiService from './service';
import { Timings } from '../../models/date';
import { ScheduleResourceEnum } from '../../models/schedule';

const serviceEndpoint = 'entity/v1';

export default class EntityService extends ApiService {
  public async getAppointmentSlots(date: Date) {
    const url = `${this.apiDomain}/${serviceEndpoint}/appointment-slotes?date=${date}`;
    return http.get(url, this.token);
  }
}
