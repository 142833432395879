import React, { Component } from 'react';

import StepForm from './StepForm';

import Header from '../header';

class Step extends Component {
  render() {
    return (
      <>
        {/* <Header /> */}
        <StepForm />
      </>
    );
  }
}

export default Step;
