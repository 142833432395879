import React from 'react';

import '../Input.scss';
import './Checkbox.scss';

interface CustomProps {
  default?: boolean[];
  id: string;
  label: string;
  onChange: (value: boolean) => void;
  gap?: 'sm' | 'md' | 'lg';
  labelgap?: 'sm' | 'md' | 'lg';
  size?: string;
  error?: string;
  name: string;
  options: any[];

  weight: 'light' | 'normal' | 'medium' | 'bold';
  color: 'light' | 'medium' | 'dark';
  fontsize: 'sm' | 'md' | 'lg';
}
interface CustomState {
  value: any;
}

class CheckboxListInput extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: [],
    gap: 'lg',
    size: '2',
    name: '',
    label: '',
    error: '',
    options: [],
    weight: 'light',
    color: 'light',
    fontsize: 'sm',
    labelgap: 'sm',
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    // console.log(prevProps.default, this.props.default);
    if (prevProps.default !== this.props.default) {
      // console.log(prevProps.default, this.props.default);
      // if (!prevProps.default) return;
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.default) this.setState({ value: this.props.default });
  }

  handleChange(event: any) {
    const optionValue = event.target.value;
    this.setState((prevState) => {
      const { value } = prevState;
      const optionValueIndex = value.indexOf(optionValue);
      if (optionValueIndex === -1) {
        value.push(optionValue);
      } else {
        value.splice(optionValueIndex, 1);
      }
      this.props.onChange(value);

      return {
        value,
      };
    });
  }

  render() {
    // console.log('state: ', this.state);
    const sizeClass = this.props.size ? 'custom-checkbox--sm' : '';
    let lineFixClass: string;

    if (this.props.size === '1') {
      lineFixClass = 'col-md-6 col-sm-3 col-6';
    } else if (this.props.size === '2') {
      lineFixClass = 'col-md-2 col-sm-3 col-6';
    } else {
      lineFixClass = 'col-md-4 col-sm-6 col-12 mb-3';
    }

    const {
      name,
      label,
      weight,
      fontsize,
      color,
      labelgap,
      gap,
      options,
      error,
    } = this.props;
    const weightClasses = weight ? `Input__label--font-weight-${weight}` : '';
    const fontsizeClasses = fontsize ? `Input__label--size-${fontsize}` : '';
    const colorClasses = color ? `Input__label--${color}` : '';
    const labelGapClasses = labelgap ? `Input__label--gap-${labelgap}` : '';

    const inputIdCandidate = name || label;
    const inputId = inputIdCandidate
      .toLowerCase()
      .split(' ')
      .join('-');

    return (
      <div className={`Input Input--gap-${gap}`}>
        {label && (
          <label
            className={`Input__label ${weightClasses} ${fontsizeClasses} ${colorClasses} ${labelGapClasses} w-100`}
            htmlFor={inputId}
          >
            {label}
          </label>
        )}
        <div className="row">
          {options.map((option: any) => {
            return (
              <div className={lineFixClass} key={option[0]}>
                <div className={`custom-checkbox ${sizeClass}`}>
                  <input
                    type="checkbox"
                    id={`checkbox-${option[0]}`}
                    name={name}
                    value={option[0]}
                    checked={
                      this.state.value
                        ? this.state.value.indexOf(option[0]) !== -1
                        : false
                    }
                    onChange={this.handleChange}
                  />
                  <label
                    className="custom-radio__label custom__label--sm"
                    htmlFor={`checkbox-${option[0]}`}
                  >
                    {option[1]}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// CheckboxListInput.propTypes = {
//   default: PropTypes.arrayOf(PropTypes.string),
//   onChange: PropTypes.func.isRequired,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   labelgap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   // options: PropTypes.array.isRequired,
//   options: PropTypes.arrayOf(PropTypes.array),
//   size: PropTypes.string,
//   weight: PropTypes.oneOf(['light', 'normal', 'medium', 'bold']),
//   color: PropTypes.oneOf(['light', 'medium', 'dark']),
//   fontsize: PropTypes.oneOf(['sm', 'md', 'lg']),
//   name: PropTypes.string,
//   label: PropTypes.string,
//   error: PropTypes.string,
// };

// CheckboxListInput.defaultProps = {
//   default: [],
//   gap: 'lg',
//   size: '2',
//   name: '',
//   label: '',
//   error: '',
//   options: [],
//   weight: 'light',
//   color: 'light',
//   fontsize: 'sm',
//   labelgap: 'sm',
// };

export default CheckboxListInput;
