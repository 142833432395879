import React, { Component } from 'react';

import './PaymentSummary.scss';

interface CustomProps {
  summary: any;
  showBackground?: boolean;
}

class PaymentSummary extends Component<CustomProps> {
  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <p className="font-base py-3 mb-0 text-body font-weight-medium">
        Payment Details
      </p>
    );
  }

  renderSummarySubtotal() {
    return (
      <li className="d-flex justify-content-between mb-2">
        <span className="text-muted">Total W/O Discount</span>
        <span className="text-body text-right">
          AED {this.props.summary.eds.totalAmount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryDiscount() {
    return (
      <li className="d-flex justify-content-between mb-2">
        <span className="text-muted">Discount</span>
        <span className="text-body text-right">
          {this.props.summary.totalDiscount > 0 ? '-' : ''} AED{' '}
          {this.props.summary.totalDiscount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryRtaAmount() {
    return (
      <li className="d-flex justify-content-between mb-2">
        <span className="text-muted">RTA Amount</span>
        <span className="text-body text-right">
          AED {this.props.summary.rta.totalAmount.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryNetAmount() {
    let netPay = 0;
    if (
      this.props.summary &&
      this.props.summary.eds &&
      this.props.summary.eds.totalAmount
    ) {
      netPay = this.props.summary.eds.totalAmount;
    }
    if (this.props.summary && this.props.summary.totalDiscount) {
      netPay -= this.props.summary.totalDiscount;
    }
    return (
      <li className="d-flex justify-content-between mb-2">
        <span className="text-muted">Net Amount</span>
        <span className="text-body text-right">AED {netPay.toFixed(2)}</span>
      </li>
    );
  }

  renderSummaryWithOutVat() {
    let netPay = 0;
    if (this.props.summary && this.props.summary.totalAmount) {
      netPay =
        this.props.summary.totalAmount - this.props.summary.totalDiscount;
    }
    return (
      <li className="d-flex justify-content-between mb-2">
        <span className="text-muted">Subtotal without VAT</span>
        <span className="text-body text-right">AED {netPay.toFixed(2)}</span>
      </li>
    );
  }

  renderSummaryTax() {
    return (
      <li className="d-flex justify-content-between mb-2">
        <span className="text-muted">
          5% VAT is applicable for Excellence Driving Fees
        </span>
        <span className="text-body text-right">
          AED {this.props.summary.totalTax.toFixed(2)}
        </span>
      </li>
    );
  }

  renderSummaryAmountPayable() {
    const netPay =
      this.props.summary.totalAmount +
      this.props.summary.totalTax -
      this.props.summary.totalDiscount;
    return (
      <li className="d-flex justify-content-between mb-2 my-4 border-bottom border-top py-3">
        <span className="text-body font-base">Amount Payable</span>
        <span className="text-body font-xlg text-right">
          AED {netPay.toFixed(2)}
        </span>
      </li>
    );
  }

  render() {
    return (
      <div
        className={`PaymentDetails mb-3 ${
          this.props.showBackground ? 'bg-white p-lg-4 p-3 rounded' : ''
        }`}
      >
        {this.renderHeader()}
        <ul className="list-unstyled p-0 m-0 font-small d-inline-block w-100">
          {this.renderSummarySubtotal()}
          {this.renderSummaryDiscount()}
          {this.renderSummaryNetAmount()}
          {this.renderSummaryRtaAmount()}
          {this.renderSummaryWithOutVat()}
          {this.renderSummaryTax()}
          {this.renderSummaryAmountPayable()}
        </ul>
      </div>
    );
  }
}

// PaymentSummary.propTypes = {
//   summary: PropTypes.shape({
//     totalAmount: PropTypes.number.isRequired,
//     totalTax: PropTypes.number.isRequired,
//     totalDiscount: PropTypes.number.isRequired,
//     rta: PropTypes.shape({
//       totalAmount: PropTypes.number.isRequired,
//     }).isRequired,
//     eds: PropTypes.shape({
//       totalAmount: PropTypes.number.isRequired,
//     }).isRequired,
//   }).isRequired,
//   showBackground: PropTypes.bool,
// };

// PaymentSummary.defaultProps = {
//   showBackground: false,
// };

export default PaymentSummary;
