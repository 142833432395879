export default ({ validators = [], fieldsToValidate = {} }) => {
  let areFieldsValid = true;
  const fields: { [key: string]: any } = { ...fieldsToValidate };

  // console.log('validators: ', validators);
  // console.log('fields:', fields);

  Object.keys(fields).forEach((fieldKey) => {
    // avoid validation when not shown
    if (fields[fieldKey].show) {
      const shouldShow = fields[fieldKey].show(fields);
      if (!shouldShow) {
        return;
      }
    }

    const fieldValidators = validators.length
      ? validators
      : fields[fieldKey].validators || [];
    for (const validator of fieldValidators) {
      const validatorMessage = validator(
        fieldKey,
        fields[fieldKey].value,
        fields[fieldKey].name || fields[fieldKey].label
      );
      fields[fieldKey].error = validatorMessage;

      if (validatorMessage.length) {
        areFieldsValid = false;
        break;
      }
    }
  });

  // this.setState((prevState) => ({
  //   fields: { ...prevState.fields, ...fields },
  // }));
  return { areFieldsValid, updatedFields: fields };
};
