import { useEffect } from 'react';
import { trackFbPageView, trackFbLead } from '../utils/fbq';
import { useLocation } from 'react-router';

export const useFbPageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    trackFbPageView();
  }, [location.pathname]);
};

export const useFbLeadTracker = () => {
  useEffect(() => {
    trackFbLead();
  }, []);
};
