import { useEffect } from 'react';
import { Dict } from '../models/interfaces';

import { useLocation } from 'react-router';
import { trackPageView, trackConversion } from '../utils/google-analytics';

export const useGAPageViewTracker = (options: Dict = {}) => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    trackPageView(page, options);
  }, [location.pathname, options]);
};

export const useGAConversionTracker = (
  category: string,
  options: Dict = {}
) => {
  useEffect(() => {
    trackConversion(category, options);
  }, [options]);
};
