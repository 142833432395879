import { Dict } from './../models/interfaces';
import { StudentDocument } from '../models/student-document';

export const isAllDocumentDataUploaded = (
  document: StudentDocument,
  ignoreNumber: boolean = false
): boolean => {
  if (!document) {
    return false;
  }
  if (!document.frontFileInfo || !document.frontFileInfo.isUploadingCompleted) {
    return false;
  }
  if (
    document.requireFrontBack &&
    (!document.backFileInfo || !document.backFileInfo.isUploadingCompleted)
  ) {
    return false;
  }
  if (
    !ignoreNumber &&
    document.requireDocumentNumber &&
    !document.documentNumber
  ) {
    return false;
  }

  return true;
};

export const isAllDocumentsUpdationCompleted = (
  documents?: Dict<StudentDocument>
) => {
  if (!documents) {
    return false;
  }
  return (
    Object.keys(documents)
      .map((doc) => isAllDocumentDataUploaded(documents[doc], true))
      .filter((val) => !val).length === 0
  );
};
