import { Dict } from '../models/interfaces';

export const trackGtagConversion = (
  options: Dict = {},
  action: string = 'conversion'
) => {
  const w = window as any;
  if (w.gtag) {
    w.gtag('event', action, options);
  }
};

export enum GtagSendTo {
  Login = 'DC-9830108/landi0/excel005+standard',
  ForgotPassword = 'DC-9830108/landi0/excel00i+standard',
  Signup = 'DC-9830108/landi0/excel007+standard',
  SignupOTP = 'DC-9830108/landi0/excel008+standard',
  Purchase = 'DC-9830108/sales0/excel0+transactions',
  Marketing = 'AW-679419924/-eX2CK3DlLkBEJTA_MMC',
}
