import React, { Component } from 'react';

import './ReferenceNumber.scss';

interface CustomProps {
  referenceNumber?: string;
  removeButtonText?: string;
  showRemoveButton?: boolean;
  onChange: () => void;
}

class ReferenceNumber extends Component<CustomProps> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onChange();
  }
  renderPromoCodeText() {
    return (
      <div className="ApplyCoupon__text">
        <span className="ApplyCoupon__title">
          {this.props.referenceNumber
            ? this.props.referenceNumber
            : ''}
        </span>
      </div>
    );
  }

  renderRemoveButton() {
    return (
      <div className="ApplyCoupon__btn">
        <button
          type="button"
          className="Button Button__link"
          onClick={() => this.onClick()}
        >
          {this.props.removeButtonText || 'Remove'}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="ApplyCoupon">
        <div className="ApplyCoupon__content">
          <div className="ApplyCoupon__icon">
          </div>
          {this.renderPromoCodeText()}
        </div>
        {this.props.showRemoveButton && this.renderRemoveButton()}
      </div>
    );
  }
}
export default ReferenceNumber;
