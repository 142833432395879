/* eslint-disable class-methods-use-this */

import io from 'socket.io-client';
import API_DOMAIN from '../api/apiDomain';
import { actions as notificationActions } from '../store/reducers/notification';
import notificationEvents from './events';
import { Dispatch } from 'redux';
import { State } from '../store/interfaces';
import { NotificationEventTypes } from './eventTypes';

export default class NotificationSocket {
  socket: any;
  store: any;

  constructor() {
    this.socket = null;
    this.store = null;
  }

  connect(store: any) {
    this.store = store;
    this.socket = io(`${API_DOMAIN}`, { path: '/notification/connect' });
    this.socket.on('connect', () => {
      // console.log(`socket connected ${this.socket.id}`);
    });
    this.socket.on('disconnect', () => {
      // console.log('socket disconnected');
    });
    this.socket.on(
      'notification:ack_with_user_id',
      this.handleSendAck.bind(this)
    );
  }

  handleSendAck(incomingData: any) {
    // console.log('incomingData: ', incomingData);
    const state: State = this.store.getState();
    const userId = state.authUser.user ? state.authUser.user._id : '';
    const { callEvent, notificationId } = incomingData;
    const dataToSend = { notificationId, userId };
    this.socket.emit(callEvent, dataToSend, this.handleAckResponse.bind(this));
  }

  handleAckResponse(response: any) {
    // console.log(response);
    if (response.status === 'something_new') {
      const { notification } = response;
      this.showMessage(notification);
      this.processEvent(notification);
    }
    if (response.status === 'something_ill') {
      // console.error(response.message);
    }
  }

  showMessage(notification: any) {
    if (!notification.message) return;
    return this.store.dispatch(notificationActions.pushNew(notification));
  }

  processEvent(notification: { event: NotificationEventTypes }) {
    const { event } = notification;
    if (!event) return;
    const eventHandler = notificationEvents[event];
    // if (typeof eventHandler !== 'function') {
    //   throw Error(`Notification Event Handler Not found for ${event}`);
    // }
    if (typeof eventHandler === 'function') {
      eventHandler.call(null, notification);
    }
  }
}
