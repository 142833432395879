import React, { Component, MutableRefObject } from 'react';

import './AccordionBlocks.scss';
import {
  AccordionBlocksCustomeProps,
  AccordionBlocksCustomeState,
  Block,
  AccordionBlockCustomeProps,
  Summaries,
} from './props';

class AccordionBlocks extends React.Component<
  AccordionBlocksCustomeProps,
  AccordionBlocksCustomeState
> {
  constructor(props: AccordionBlocksCustomeProps) {
    super(props);
    this.state = {
      // blocks: [
      //   {
      //     summaries: { key1: { label: 'foo is', value: 'bar'} }
      //     touched: false
      //   },
      //   ...
      // ]

      blocks: [],
      currentBlockIndex: this.props.currentIndex || 0,
      blockCount: 0,
    };

    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.initializeBlocks();
  }

  initializeBlocks() {
    const { children } = this.props;
    const blockCount = React.Children.count(children);
    const blocks: Block[] = [];
    React.Children.forEach(
      children,
      (element: Component<AccordionBlockCustomeProps>, index) => {
        let touched = false;
        if (this.props.allowParallel || index <= this.state.currentBlockIndex) {
          touched = true;
        }
        blocks.push({
          touched,
          summaries:
            element.props.summaries &&
            Object.keys(element.props.summaries).length
              ? element.props.summaries
              : {},
        });
      }
    );
    this.setState({ blocks, blockCount });
  }

  gotoNextBlock(requestingBlockIndex: number) {
    const { blocks } = this.state;
    if (requestingBlockIndex >= this.state.blockCount - 1) return;
    const nextBlockIndex = requestingBlockIndex + 1;
    const isNextBlockTouched = blocks[nextBlockIndex].touched;
    if (isNextBlockTouched) {
      this.setState({
        currentBlockIndex: nextBlockIndex,
      });
      return;
    }
    blocks[nextBlockIndex] = { ...blocks[nextBlockIndex], touched: true };
    this.setState({
      currentBlockIndex: nextBlockIndex,
      blocks,
    });
  }

  gotoPreviousBlock(requestingBlockIndex: number) {
    const { blocks } = this.state;

    if (requestingBlockIndex > this.state.currentBlockIndex) return;
    const nextBlockIndex = requestingBlockIndex - 1;
    if (!blocks[nextBlockIndex]) return;
    const isNextBlockTouched = blocks[nextBlockIndex].touched;
    if (isNextBlockTouched) {
      this.setState({
        currentBlockIndex: nextBlockIndex,
      });
      return;
    }
    blocks[nextBlockIndex] = { ...blocks[nextBlockIndex], touched: true };
    this.setState({
      currentBlockIndex: nextBlockIndex,
      blocks,
    });
  }

  scrollTo = (ref?: MutableRefObject<any>) =>
    ref && window.scrollTo(0, ref.current.offsetTop);

  openBlock(index: number, ref?: MutableRefObject<any>) {
    const isBlockTouched =
      this.props.allowParallel || index <= (this.props.currentIndex || 0);
    if (!isBlockTouched) return;
    this.setState({
      currentBlockIndex: index,
    });
    this.scrollTo(ref);
  }

  addSummary(blockIndex: number, summary: Summaries) {
    const { blocks } = this.state;
    const { key, label, value } = summary;
    blocks[blockIndex].summaries[key] = { label, value };
    this.setState({ blocks });
  }

  renderCards() {
    const { children, showAll = false } = this.props;
    const { blocks, currentBlockIndex } = this.state;
    const blockCount = React.Children.count(children);
    const renderedCards = React.Children.map(
      children,
      (element: any, index: number) => {
        const parentProps = {
          // key: element.key,
          addSummary: (key: string, label: string, value: any) =>
            this.addSummary(index, { key, label, value }),
          gotoNextBlock:
            index < blockCount - 1 ? () => this.gotoNextBlock(index) : null,
          gotoPreviousBlock:
            index > 0 ? () => this.gotoPreviousBlock(index) : null,
          openBlock: (ref?: MutableRefObject<any>) =>
            this.openBlock(index, ref),
          isCurrent: index === currentBlockIndex,
          isTouched: index <= currentBlockIndex,
          summaries: blocks[index] ? blocks[index].summaries : {},
          overrideTick: this.props.overrideTick,
          showAll,
        };

        return (
          <element.type
            {...element.props}
            {...parentProps}
            summaries={
              Object.keys(element.props.summaries).length === 0 && blocks[index]
                ? blocks[index].summaries
                : element.props.summaries
            }
          />
        );
      }
    );
    return renderedCards;
  }

  render() {
    return (
      <>
        <div className="AccordionBlocks">{this.renderCards()}</div>
      </>
    );
  }
}

export default AccordionBlocks;
