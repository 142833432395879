import ApiService from './service';

import * as http from './http';

const curriculumEndPoint = 'curriculum/v1';

export default class CurriculumService extends ApiService {
  public async getAssessmentResultDetails(
    assessmentId: number | string,

    instanceCode: string
  ) {
    const url = `${this.apiDomain}/${curriculumEndPoint}/assessment-results/${assessmentId}?instanceCode=${instanceCode}`;

    return http.get(url, this.token);
  }

  public async getStudentRtaErrorList(studentId: string | undefined) {
    if (!studentId) return;

    const url = `${this.apiDomain}/integrator/v1/getRtaErrorCodeList?studentId=${studentId}`;
    const response = http.get(url, this.token);
    return response;
  }

  public async getFeedbackQuestionList() {
    const url = `${this.apiDomain}/schedule/v1/student-feedback/feedback-questions/list`;
    const response = http.get(url, this.token);
    return response;
  }
}
