import React from 'react';
import '../Input.scss';
import './Checkbox.scss';

interface CustomProps {
  default?: boolean;
  id: string;
  label: string;
  onChange: (value: boolean) => void;
  gap?: 'sm' | 'md' | 'lg';
  size?: string;
  error?: string;
  name: string;
  options: any[];
}

interface CustomState {
  value: any;
}

class CheckboxInput extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    gap: 'lg',
    size: '2',
    default: false,
    error: '',
    name: '',
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    // console.log(prevProps.default, this.props.default);
    if (prevProps.default !== this.props.default) {
      // console.log(prevProps.default, this.props.default);
      // if (!prevProps.default) return;
      this.updateDefault();
    }
  }

  updateDefault() {
    this.setState({ value: this.props.default });
  }

  handleChange() {
    this.setState((prevState) => {
      this.props.onChange(!prevState.value);
      return {
        value: !prevState.value,
      };
    });
  }

  render() {
    const { size, gap, options, name, error } = this.props;
    const sizeClass = size ? 'custom-checkbox--sm' : '';

    return (
      <div className={`Input Input--gap-${gap}`}>
        <div className={`custom-checkbox ${sizeClass}`}>
          <input
            type="checkbox"
            id={`checkbox-${options[0]}`}
            name={name}
            value={options[0]}
            checked={this.state.value}
            onChange={this.handleChange}
          />
          <label
            className="custom-radio__label custom__label--sm"
            htmlFor={`checkbox-${options[0]}`}
          >
            {options[1]}
          </label>
        </div>
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// CheckboxInput.propTypes = {
//   default: PropTypes.bool,
//   // placeholder: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   options: PropTypes.arrayOf(PropTypes.any).isRequired,
//   size: PropTypes.string,
//   name: PropTypes.string,
//   error: PropTypes.string,
// };

// CheckboxInput.defaultProps = {
//   gap: 'lg',
//   size: '2',
//   default: false,
//   name: '',
//   error: '',
//   // placeholder: '',
// };

export default CheckboxInput;
