import React from 'react';
import { connect, useSelector } from 'react-redux';

import {
  registrationStages,
  paymentPlans,
} from '../../store/reducers/registration';
import {
  AccordionBlocks,
  AccordionBlock,
} from '../../components/accordion-blocks';
import PersonalDetail from './PersonalDetail';
import CourseType from './CourseType';
import PaymentPlans from './PaymentPlans';
import PaymentMethod from './PaymentMethod';
import Header from '../header';
import Footer from '../footer';

import PersonalDetailIcon from '../../assets/images/steps/personal-details.svg';
import CourseTypeIcon from '../../assets/images/steps/course-type.svg';
import PaymentPlanIcon from '../../assets/images/steps/choose-payment-plan.svg';
import PaymentMethodIcon from '../../assets/images/steps/choose-payment-method.svg';
import User, { StudentCourse } from '../../models/user';
import { ContainerLoader, FullPageLoader } from '../../components/loader';
import { State } from '../../store/interfaces';
import { useHistory } from 'react-router';
import { RouteKeys } from '../route-keys';

const Step: React.SFC = () => {
  const {
    user,
    currentStage,
    courseType,
    course,
    summary,
    paymentPlan,
    token
  } = useSelector((state: State) => state.registration);
  
  const {
    currentIndex,
    personalDetailsSummary,
    courseTypeSummary,
    paymentPlanSummary,
  } = getIndexAndSummaries(
    user,
    currentStage,
    courseType || '',
    course,
    summary,
    paymentPlan || ''
  );

  const history = useHistory();
  
  if(!token){
    history.replace(RouteKeys.Dashboard)
  }

  if (currentIndex === 4) {
    // Registration
    history.replace('/dashboard/create-student-finish');
    return <FullPageLoader></FullPageLoader>;
  }

  return (
    <>
      {/* <Header /> */}
      <div className="py-5 my-lg-4">
        {!user && (
          <ContainerLoader
            height={500}
            text={'Fetching registration details..'}
          />
        )}
        {user && (
          <div className="container">
            <div className="text-center">
              <h3 className="font-weight-normal">
                <span className="text-primary">{user.name}</span>
              </h3>
              <p className="text-muted font-lg mb-5">
                Please fill the following Steps
              </p>
            </div>
            <AccordionBlocks currentIndex={currentIndex}>
              <AccordionBlock
                title="Personal Detail"
                icon={PersonalDetailIcon}
                actionText="Please Fill Your Personal Details"
                summaries={personalDetailsSummary}
              >
                <PersonalDetail />
              </AccordionBlock>
              <AccordionBlock
                title="Course Type"
                icon={CourseTypeIcon}
                actionText="Choose Your Course Type"
                summaries={courseTypeSummary}
              >
                <CourseType />
              </AccordionBlock>
              <AccordionBlock
                title="Choose Payment Plan"
                icon={PaymentPlanIcon}
                actionText="Choose your payment plan that works best for you"
                summaries={paymentPlanSummary}
              >
                <PaymentPlans />
              </AccordionBlock>
              <AccordionBlock
                title="Complete Payment"
                icon={PaymentMethodIcon}
                actionText="Let's finish powering you up"
              >
                <PaymentMethod />
              </AccordionBlock>
            </AccordionBlocks>
          </div>
        )}
      </div>
      {/* <Footer /> */}
    </>
  );
};

Step.defaultProps = {
  personalDetailsSummary: {},
  courseTypesSummary: {},
  paymentPlanSummary: {},
  courseType: '',
  course: {},
  summary: {},
  paymentPlan: '',
};

const getPersonalDetailsSummary = (user?: User) =>
  user
    ? {
        licensetype: {
          label: 'Your required license type',
          value: user.studentDetails
            ? user.studentDetails.requiredLicenseCategory
            : '',
        },
        nationality: {
          label: 'and Nationality is',
          value: user.nationality,
        },
      }
    : {};

const getCourseTypeSummary = (courseType: string, course: StudentCourse) =>
  courseType && course && course.amount
    ? {
        coursetype: {
          label: 'Your choose course as',
          value: courseType,
        },
        courseprice: {
          label: 'course price is',
          value:
            course && course.amount
              ? `AED ${course.amount.totalAmount - course.amount.totalDiscount}`
              : '',
        },
      }
    : {};
const getPaymentPlanSummary = (
  fullPaymentSelected: boolean,
  totalAmount: number
) => ({
  paymentplan: {
    label: 'Your choose payment plan as',
    value: fullPaymentSelected ? 'Full Payment' : 'Stage payment',
  },
  paymableamount: {
    label: 'and amount payable is',
    value: `AED ${totalAmount.toFixed(2)}`,
  },
});

const getIndexAndSummaries = (
  user: User | undefined,
  currentStage: string,
  courseType?: string,
  course?: any,
  summary?: any,
  paymentPlan?: string
) => {
  const totalAmount =
    summary.totalAmount + summary.totalTax - summary.totalDiscount;
  const fullPaymentSelected = paymentPlan === paymentPlans.fullPayment;
  switch (currentStage) {
    case registrationStages.profileDetails:
      return {
        currentIndex: 0,
        personalDetailsSummary: {},
        courseTypeSummary: {},
        paymentPlanSummary: {},
      };
    case registrationStages.courseSelection:
      return {
        currentIndex: 1,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: {},
        paymentPlanSummary: {},
      };
    case registrationStages.courseSelected:
      return {
        currentIndex: 2,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: getCourseTypeSummary(courseType as string, course),
        paymentPlanSummary: {},
      };
    case registrationStages.completePayment:
      return {
        currentIndex: 3,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: getCourseTypeSummary(courseType as string, course),
        paymentPlanSummary: getPaymentPlanSummary(
          fullPaymentSelected,
          totalAmount
        ),
      };
    case registrationStages.finished:
      return { currentIndex: 4 };
    default:
      return { currentIndex: 0 };
  }
};

export default Step;
