import api from '../api';
import unauthorizedApiResponseInterceptor from '../api/interceptor';

import { actions as userActions } from './reducers/authUser';
import { actions as commonActions } from './reducers/common';
import { State } from './interfaces';
import notificationClient from '../notifications/client';

const syncIntervalInSeconds = 30;

const syncProfile = (store: any) => {
  try {
    const state = store.getState();
    if (state.authUser.token) {
      store.dispatch(userActions.fetchProfile());
    }
  } catch (e) {
    // console.log(e)
  }
};

const initialize = (store: any) => {
  notificationClient.connect(store);

  const state = store.getState();
  unauthorizedApiResponseInterceptor(() => {
    store.dispatch(userActions.logout());
    store.dispatch(commonActions.clearData());
    api.setToken(undefined);
    window.location.reload();
  });

  api.setToken(state.authUser.token);
  if (state.authUser.token) {
    api.setToken(state.authUser.token);
    store.dispatch(userActions.fetchProfile());
    // setInterval(async () => syncProfile(store), syncIntervalInSeconds * 1000);
  }
};

export default initialize;
