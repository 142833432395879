import React, { Component } from 'react';

import './PaymentOption.scss';

interface CustomProps {
  label: string;
  selected?: boolean;
  id: string;
  icon: string;
  disabled?: boolean;
  primarySubText?: boolean;
  onSelect?: () => void;
}

class PaymentOption extends Component<CustomProps> {
  static defaultProps: Partial<CustomProps> = {
    selected: false,
    id: 'pay-online',
    disabled: false,
  };
  renderInput() {
    return (
      <input
        type="radio"
        id={this.props.id}
        name="payment"
        disabled={this.props.disabled}
        checked={this.props.selected}
        value={'payment'}
      />
    );
  }

  renderLabelText = () => {
    return (
      <div className="PaymentRadio__text">
        <i className={`${this.props.icon} PaymentRadio__icon`} />
        {this.props.label}
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderLabelIcon() {
    return (
      <div
        className={`PaymentRadio__round ${
          this.props.selected ? 'PaymentRadio__round__selected' : ''
        }`}
      >
        <span className="circle-outline">
          <span className="circle-fill" />
        </span>
      </div>
    );
  }

  renderLabel() {
    return (
      <label
        className="PaymentRadio__label"
        htmlFor={this.props.id}
        onClick={(e) => {
          e.preventDefault();
          if (this.props.onSelect) {
            this.props.onSelect();
          }
        }}
      >
        <div className="PaymentRadio-method">
          {this.renderLabelIcon()}
          {this.renderLabelText()}
        </div>
      </label>
    );
  }

  render() {
    return (
      <div className="PaymentRadio">
        {/* {this.renderInput()} */}
        {this.renderLabel()}
      </div>
    );
  }
}

// PaymentOption.propTypes = {
//   label: PropTypes.string.isRequired,
//   selected: PropTypes.bool,
//   id: PropTypes.string,
//   icon: PropTypes.string.isRequired,
//   disabled: PropTypes.bool,
// };

export default PaymentOption;
