import DateInput from './date-input/DateInput';
import OTPInput from './otp-input';
import PasswordInput from './password-input';
import RadioSelectInput from './radio-select-input';
import SelectTextInput from './select-text-input';
import TextInput from './text-input';
import SelectPhoneInput from './select-phone-input';
import CheckboxInput from './checkbox';

export default {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  SelectTextInput,
  OTPInput,
  DateInput,
  SelectPhoneInput,
  CheckboxInput,
};

export {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  SelectTextInput,
  OTPInput,
  DateInput,
  SelectPhoneInput,
  CheckboxInput,
};
