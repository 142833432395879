import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { FullPageLoader } from '../../components/loader';

import { State } from '../../store/interfaces';
import {
  AuthState,
  actions as userActions,
} from '../../store/reducers/authUser';
import { useSelector } from 'react-redux';


const VerifyEmail = () => {
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);

  const { id, verifyToken } = useParams();

  const { user, userProfileLoaded } = useSelector<State, AuthState>(
    (state: State) => state.authUser
  );
  useEffect(() => {
    (async () => {
      try {
        const data = await api.user.verifyEmail(
          id as string,
          verifyToken as string
        );
        toastr.success('Success', data.data.message);
        setResult(true);
        // toastr.success('Success', 'Email verified successfully');
      } catch (error) {
        setResult(false);
        setError(true);
      }
    })(); // IIFE
  }, []);

  if(!user){
    return <Redirect to="/auth/login" />;    
  }
  if (result) {
    return <Redirect to="/" />;
  } else if (error) {
    return (
      <div>
        <h4>Email Verification Failed</h4>
      </div>
    );
  } else {
    return <FullPageLoader />;
  }
  return null;
};

export default VerifyEmail;
