function findNestedObjectValue(obj: any, keyChain: any[]): any {
  // console.log('\n obj, keyChain: ', obj, keyChain);
  if (!obj) {
    return;
  }
  const key = keyChain.shift();
  const keyValue = obj[key];
  // console.log(
  //   'keyChain.length, keyChain, obj, key, keyValue: ',
  //   keyChain.length,
  //   keyChain,
  //   obj,
  //   key,
  //   keyValue
  // );
  if (!keyChain.length) {
    return keyValue;
  }

  return findNestedObjectValue(keyValue, keyChain);
}

export default findNestedObjectValue;
