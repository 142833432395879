import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import Routes from './Routes';

import '../styles/base.scss';
import { withTracker } from './tracker/with-tracker';

class App extends Component {
  render() {
    return (
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
        />
        <Routes />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

// without using withRouter, route changes only reflect in url but not on page.
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTracker(App))
);
