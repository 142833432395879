import React from 'react';
import { Sentry } from '../logger';

interface CustomProps {
  children: any;
}
interface CustomState {
  hasError?: boolean;
  eventId?: any;
}
class ErrorBoundary extends React.Component<CustomProps, CustomState> {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: CustomProps) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // log the error to an error reporting service
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <button
          onClick={() =>
            Sentry.showReportDialog({ eventId: this.state.eventId })
          }
        >
          Report feedback
        </button>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
