import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './CoursePlan.scss';
import Button from '../../button';
import { formatMultiLanguageText } from '../../../utils/multi-language-utils';
import { StudentCourse } from '../../../models/user';

interface CustomProps {
  course: StudentCourse;
  onSelect: (course: any) => void;
  onChangeExpanded: () => void;
  isExpanded: boolean;
}

class CoursePlan extends Component<CustomProps> {
  renderPlanHeaderAmount() {
    return (
      <h3 className="Plan__price">
        AED{' '}
        {this.props.course.amount.totalAmount -
          this.props.course.amount.totalDiscount}
      </h3>
    );
  }

  getAmountPerClass(): number | undefined {
    if (this.props.course.courseType.isLumpSumCourse) {
      return undefined;
    }
    const stage = this.props.course.stages.find(
      (st) =>
        st.feeCodes.find((fc) =>
          this.props.course.course.amountPerClassFeeCodes.includes(fc.code)
        ) !== undefined
    );
    if (!stage) {
      return undefined;
    }

    const feeCode = stage.feeCodes.find((fc) =>
      this.props.course.course.amountPerClassFeeCodes.includes(fc.code)
    );

    if (!feeCode) {
      return undefined;
    }
    return feeCode.amountPerQuantity * (stage.noOfClassesPerHour || 1);
  }

  renderLumpSumDisclaimer() {
    return (
      <span className="d-block font-xs text-muted line-height-normal">
        (License Guaranteed)<sup className="text-primary">**</sup>
      </span>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanHeaderCourseTypeName(courseType: string) {
    return <span className="Plan__type">{courseType}</span>;
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanHeaderNote() {
    return <p className="Plan__note">(5% VAT applicable on Excellence Fees)</p>;
  }

  renderPlanHeaderSelectButton(courseType: string) {
    return (
      <div className="Plan__btn">
        <Button
          type="button"
          size="sm"
          onClick={() => {
            this.props.onSelect(this.props.course);
          }}
        >
          Choose {courseType}
        </Button>
      </div>
    );
  }

  renderPlanHeaderOriginalCourseAmount() {
    return (
      <div>
        <span className="Plan__originalamount">
          AED {this.props.course.amount.totalAmount}
        </span>
      </div>
    );
  }

  renderPlanHeaderDiscountInfo() {
    return (
      <span className="Plan__discountinfo">
        You save AED {this.props.course.amount.totalDiscount?.toFixed(2)}
      </span>
    );
  }

  renderPlanHeader() {
    let courseType;
    if (this.props.course.courseType.isLumpSumCourse) {
      courseType = this.props.course.courseType.isVipCourse
        ? 'VIP Lumpsum'
        : 'Lumpsum';
    } else {
      courseType = this.props.course.courseType.name.en;
    }
    return (
      <div className="Plan__content">
        <span className="Plan__type">{courseType}</span>
        {this.renderPlanHeaderAmount()}
        {this.props.course.amount.totalDiscount > 0 &&
          this.renderPlanHeaderOriginalCourseAmount()}
        {this.props.course.amount.totalDiscount > 0 &&
          this.renderPlanHeaderDiscountInfo()}
        <div className="">
          {this.props.course.courseType.isLumpSumCourse &&
            this.renderLumpSumDisclaimer()}
          {this.renderPlanHeaderNote()}
          {this.renderPlanHeaderSelectButton(courseType)}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanListItem(
    label: string,
    value: string | number,
    key: string | number
  ) {
    return (
      <li className="Plan__list-item" key={`${key}`}>
        <span className="Plan__list-item-label">{label}</span>
        <p>{value}</p>
      </li>
    );
  }

  renderReadMoreLessButton() {
    return (
      <div className="Plan__readmore">
        <button
          type="button"
          className="Button Button__link"
          onClick={this.props.onChangeExpanded}
        >
          {this.props.isExpanded ? 'Read less' : 'Read more'}
        </button>
      </div>
    );
  }

  renderInfoList() {
    const { courseType } = this.props.course;
    const infoList = [
      {
        label: 'Week Sequence',
        value:
          courseType.weekSequences && courseType.weekSequences.length
            ? formatMultiLanguageText(courseType.weekSequences[0].name) ||
              'All days'
            : 'All days',
      },
      {
        label: 'Number of hours per week',
        value: `${courseType.noOfHoursPerWeek} hrs`,
      },
      {
        label: 'Time allowed in the class type',
        value: '8.30 AM-5.30 PM & 8.00 PM-11.00 PM',
      },
      {
        label: 'Training fees per hour',
        value: courseType.isLumpSumCourse
          ? 'N/A'
          : `AED ${this.getAmountPerClass()}`,
      },
      {
        label: 'Minimum Booking duration',
        value: `${courseType.minimumClassDuration} hr`,
      },
      {
        label: 'Maximum Booking duration',
        value: `${courseType.maximumClassDuration} hr`,
      },
    ];

    return (
      <ul className={`Plan__list ${this.props.isExpanded ? 'Expanded' : ''}`}>
        {infoList.map((info, index) =>
          this.renderPlanListItem(info.label, info.value, index)
        )}
      </ul>
    );
  }

  render() {
    return (
      <div className="Plan-box">
        <div className="Plan-inner">
          {this.renderPlanHeader()}
          {this.renderInfoList()}
          {this.renderReadMoreLessButton()}
        </div>
      </div>
    );
  }
}

export default CoursePlan;
