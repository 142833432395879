import {
  SubmitAnswersRequestModel,
  SubTopicVideoProgressRequest,
} from './../models/lecture';
import * as http from './http';
import ApiService from './service';
import { string } from 'prop-types';
const serviceEndpoint = 'user/v1';

export default class LectureService extends ApiService {
  public async getCurrentLecture(licenseTypeId: string, language?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/lecture?licenseTypeId=${licenseTypeId}&language=${language}`;
    return http.get(url, this.token);
  }

  public async submitAnswers(
    lectureId: string,
    topicId: string,
    data: SubmitAnswersRequestModel
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/lecture/question`;
    return http.put(url, data, this.token);
  }

  public async reportSubTopicVideoProgress(data: SubTopicVideoProgressRequest) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/lecture/sub-topic`;
    return http.put(url, data, this.token);
  }
}
