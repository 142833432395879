import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentPlanOption from '../payment-plan-option';
import PaymentPlanStructure from '../payment-plan-structure';
import {
  actions as registrationActions,
  paymentPlans,
} from '../../../store/reducers/registration';

import './PaymentPlan.scss';

interface CustomProps {
  course?: any;
  allowPartialPayment: boolean;
  selected: boolean;
  onSelect?: () => void;
  totalForMilestones?: number;
  selectedMilestones?: any[];
  onMilestoneSelection: (milestone: any, selected: boolean) => void;
  id: string;
  name: string;
  description: string;
  totalAmount?: number;
  totalEdsAmount?: number;
  totalRtaAmount?: number;
  primary?: boolean;
}

class PaymentPlan extends Component<CustomProps> {
  renderHeader() {
    return <PaymentPlanOption {...this.props} />;
  }

  renderStructure() {
    return (
      <PaymentPlanStructure
        course={this.props.course}
        onMilestoneSelection={(ml: any, s: boolean) => {
          this.props.onMilestoneSelection(ml, s);
        }}
        selectedMilestones={this.props.selectedMilestones}
        totalForMilestones={this.props.totalForMilestones}
      />
    );
  }

  render() {
    return (
      <div className="PaymentPlan__option">
        {this.renderHeader()}
        {this.props.selected &&
          this.props.allowPartialPayment &&
          this.renderStructure()}
      </div>
    );
  }
}

// PaymentPlan.propTypes = {
//   course: PropTypes.shape({}).isRequired,
//   allowPartialPayment: PropTypes.bool.isRequired,
//   selected: PropTypes.bool.isRequired,
//   onSelect: PropTypes.func.isRequired,
//   totalForMilestones: PropTypes.number.isRequired,
//   selectedMilestones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   onMilestoneSelection: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   totalAmount: PropTypes.number.isRequired,
//   totalEdsAmount: PropTypes.number.isRequired,
//   totalRtaAmount: PropTypes.number.isRequired,
// };

const mapStateToProps = (state: any) => ({
  isLoading: state.registration.isLoading,
  user: state.registration.user,
  course: state.registration.course,
  summary: state.registration.summary,
  fullPaymentSelected:
    state.registration.paymentPlan === paymentPlans.fullPayment,
  selectedMilestones: state.registration.stagePayment.milestones,
  totalForMilestones: state.registration.stagePayment.summary.totalAmount,
});

const mapDispatchToProps = (dispatch: any) => ({
  selectStagePayment: () =>
    dispatch(registrationActions.changePaymentPlan(paymentPlans.stagePayment)),
  selectFullPayment: () =>
    dispatch(registrationActions.changePaymentPlan(paymentPlans.fullPayment)),
  onMilestoneSelection: (milestone: any, selected: boolean) =>
    dispatch(registrationActions.selectMilestone(milestone, selected)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlan);
