import { Dispatch } from 'redux';

// action types
export const types = {
  // reset data
  RESET_DATA: 'COMMON/RESET_DATA',

  // student profile
  FETCH_PROFILE_REQUEST: 'AUTH/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'AUTH/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_ERROR: 'AUTH/FETCH_PROFILE_ERROR',
};

export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
};

export default { types, actions };
