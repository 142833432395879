import React from 'react';
// import PropTypes from 'prop-types';
import CouponCard from '../coupon-card';

import './SlideBox.scss';

import ApplyPromoCode from '../apply-promocode';

interface CustomProps {
  onApply: (value: string) => void;
  onClose: (value: string) => void;
  promoCode?: string;
  course?: any;
  promotions?: any[];
  classValue: string;
  showPromoCodeBox: boolean;
  classes: string;
}

interface CustomState {
  value: string;
  classes: string;
  showPromoCodeBox: boolean;
}
class SlideBox extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {};

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: '',
      classes: '',
      showPromoCodeBox: false,
    };
    this.onApply = this.onApply.bind(this);
    this.onClick = this.onClick.bind(this);
    // this.handleSearch = this.handleSearch.bind(this);
    // this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  onApply(value: string) {
    this.props.onApply(value);
  }
  onClick() {
    this.props.onApply('');
    // this.setState({ classes: 'SlideBox' });
  }
  render() {
    let classes = 'SlideBox';
    if (this.props.showPromoCodeBox) {
      classes = 'SlideBox active';
    }
    return (
      <div className={classes}>
        <div className="SlideBoxInner">
          {/* <Close pos="top-left" link="#"></Close> */}
          <div className="SlideBox__close" onClick={this.onClick}>
            <i className="icon-close"></i>
          </div>
          <div>
            {/* <TextInput
              placeholder="Enter Promo code"
              label="Promocode"
            ></TextInput>
            <Button>Apply</Button> */}
            <ApplyPromoCode onApply={this.onApply} />
            <CouponCard
              promotions={this.props.promotions}
              onApply={this.onApply}
            />
          </div>
        </div>
      </div>
    );
  }
}

// SlideBox.propTypes = {
//   onSearch: PropTypes.func.isRequired,
// };

// SlideBox.defaultProps = {
//   showPromoCodeBox: false,
// };

export default SlideBox;
