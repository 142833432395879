import {
  CourseMilestone,
  Course,
  StudentCourseAmounts,
} from './../../models/course';
import { MultiLanguageText } from './../../models/text';
import { PaymentReferenceInfo } from './../../models/payment';
import { getHost } from './../../utils/host';
import { Amounts } from '../../models/amounts';
// import * as UserApi from '../api/user';

import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import api from '../../api';
import { types as commonTypes } from './common';
import User, { StudentCourse } from '../../models/user';
import { State } from '../interfaces';
import { RouteKeys } from '../../containers/route-keys';
import { PANEL_ID } from '@storybook/addon-actions';
import { Dict } from '../../models/interfaces';

// action types
export const types = {
  // course
  GET_COURSES_REQUEST: 'PAYMENT/GET_COURSES_REQUEST',
  GET_COURSES_SUCCESS: 'PAYMENT/GET_COURSES_SUCCESS',
  GET_COURSES_ERROR: 'PAYMENT/GET_COURSES_ERROR',

  // promotion
  REMOVE_PROMOTION: 'PAYMENT/REMOVE_PROMOTION',
  APPLY_PROMOTION: 'PAYMENT/APPLY_PROMOTION',

  // payment plan
  CHANGE_PAYMENT_PLAN: 'PAYMENT/CHANGE_PAYMENT_PLAN',
  CHANGE_PAYMENT_METHOD: 'PAYMENT/CHANGE_PAYMENT_METHOD',
  SELECT_MILESTONE: 'PAYMENT/SELECT_MILESTONE',
  SELECT_ADDON: 'PAYMENT/SELECT_ADD_ON',

  // payment
  PAYMENT_INITIATE_REQUEST: 'PAYMENT/PAYMENT_INITIATE_REQUEST',
  PAYMENT_INITIATE_SUCCESS: 'PAYMENT/PAYMENT_INITIATE_SUCCESS',
  PAYMENT_INITIATE_ERROR: 'PAYMENT/PAYMENT_INITIATE_ERROR',
  VERIFY_PAYMENT_REQUEST: 'PAYMENT/VERIFY_PAYMENT_REQUEST',
  VERIFY_PAYMENT_SUCCESS: 'PAYMENT/VERIFY_PAYMENT_SUCCESS',
  VERIFY_PAYMENT_FAILED: 'PAYMENT/VERIFY_PAYMENT_FAILED',
  VERIFY_PAYMENT_ERROR: 'PAYMENT/VERIFY_PAYMENT_ERROR',

  GET_PAYMENT_REQUEST: 'PAYMENT/GET_PAYMENT_REQUEST',
  GET_PAYMENT_SUCCESS: 'PAYMENT/GET_PAYMENT_SUCCESS',
  GET_PAYMENT_ERROR: 'PAYMENT/GET_PAYMENT_ERROR',

  GET_PAYMENT_DETAILS_REQUEST: 'PAYMENT/GET_PAYMENT_DETAILS_REQUEST',
  GET_PAYMENT_DETAILS_SUCCESS: 'PAYMENT/GET_PAYMENT_DETAILS_SUCCESS',
  GET_PAYMENT_DETAILS_ERROR: 'PAYMENT/GET_PAYMENT_DETAILS_ERROR',

  ADDITIONAL_PAYMENT_INITIATE_REQUEST:
    'PAYMENT/ADDITIONAL_PAYMENT_INITIATE_REQUEST',
  ADDITIONAL_PAYMENT_INITIATE_SUCCESS:
    'PAYMENT/ADDITIONAL_PAYMENT_INITIATE_SUCCESS',
  ADDITIONAL_PAYMENT_INITIATE_ERROR:
    'PAYMENT/ADDITIONAL_PAYMENT_INITIATE_ERROR',
};

export enum paymentModes {
  online = 'Online',
  edsCenter = 'At EDC Center',
}

export enum OnlinePaymentStatus {
  initiated = 'Initited',
  processing = 'processing',
  verifying = 'verifying',
  completed = 'completed',
  failed = 'failed',
}

export enum paymentPlans {
  fullPayment = 'Full Payment',
  stagePayment = 'Stage Payment',
}

export enum paymentErrorCodes {
  threeDSErrorOne = '3DS authentication was attempted but was not or could not be completed; possible reasons being either the card or its Issuing Bank has yet to participate in 3DS, or cardholder ran out of time to authorize.',
  threeDSErrorTwo = '3DS authentication is either failed or could not be attempted; possible reasons being both card and Issuing Bank are not secured by 3DS, technical errors, or improper configuration.',
  authError = 'Authentication was attempted but was not or could not be completed; possible reasons being either the card or its Issuing Bank has yet to participate in 3DS.',
}

export enum paymentErrors {
  THREE_DS_NOT_AUTHENTICATED = 'The Payment transaction is failed due to non-compliance to 3DS Authentication.',
  AUTHORISATION_FAILED = 'The Payment transaction is rejected due to failure in authorization',
}

const summaryMapReduce: {
  initial: Amounts;
  reduce: (prev: any, current: any) => Amounts;
} = {
  initial: {
    amountRemaining: 0,
    noTaxableAmount: 0,
    totalAmountWithDiscount: 0,
    totalAmountWithTax: 0,
    roundOff: 0,
    taxableAmount: 0,
    totalAmount: 0,
    totalAmountPaid: 0,
    totalDiscount: 0,
    totalTax: 0,
    totalTaxPaid: 0,
    processigFee: 0,
    eds: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
      price: 0,
      amount: 0,
      discount: 0,
      netAmount: 0,
    },
    rta: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
      price: 0,
      amount: 0,
      discount: 0,
      netAmount: 0,
    },
  },
  reduce: (prev: any, current: any) => {
    if (!current.amount) {
      return prev;
    }
    return {
      amountRemaining: prev.amountRemaining + current.amount.selectMilestone,
      noTaxableAmount: prev.noTaxableAmount + current.amount.noTaxableAmount,
      // quantity: prev.quantity + current.amount.quantity,
      roundOff: prev.roundOff + current.amount.roundOff,
      taxableAmount: prev.taxableAmount + current.amount.taxableAmount,
      totalAmount: prev.totalAmount + current.amount.totalAmount,
      totalAmountPaid: prev.totalAmountPaid + current.amount.totalAmountPaid,
      totalDiscount: prev.totalDiscount + current.amount.totalDiscount,
      totalTax: prev.totalTax + current.amount.totalTax,
      totalTaxPaid: prev.totalTaxPaid + current.amount.totalTaxPaid,
      processigFee: 0,
      totalAmountWithDiscount:
        prev.totalAmountWithDiscount + current.amount.totalAmountWithDiscount,
      totalAmountWithTax:
        prev.totalAmountWithTax + current.amount.totalAmountWithTax,
      eds: {
        pricePerQuantity:
          prev.eds.pricePerQuantity + current.amount.eds.pricePerQuantity,
        taxPerQuantity:
          prev.eds.taxPerQuantity + current.amount.eds.taxPerQuantity,
        totalAmount: prev.eds.totalAmount + current.amount.eds.totalAmount,
        totalAmountPaid:
          prev.eds.totalAmountPaid + current.amount.eds.totalAmountPaid,
        totalDiscount:
          prev.eds.totalDiscount + current.amount.eds.totalDiscount,
        totalTax: prev.eds.totalTax + current.amount.eds.totalTax,
        totalTaxPaid: prev.eds.totalTaxPaid + current.amount.eds.totalTaxPaid,
        price: 0,
        amount: 0,
        discount: 0,
        netAmount: 0,
      },
      rta: {
        pricePerQuantity:
          prev.rta.pricePerQuantity + current.amount.rta.pricePerQuantity,
        taxPerQuantity:
          prev.rta.taxPerQuantity + current.amount.rta.taxPerQuantity,
        totalAmount: prev.rta.totalAmount + current.amount.rta.totalAmount,
        totalAmountPaid:
          prev.rta.totalAmountPaid + current.amount.rta.totalAmountPaid,
        totalDiscount:
          prev.rta.totalDiscount + current.amount.rta.totalDiscount,
        totalTax: prev.rta.totalTax + current.amount.rta.totalTax,
        totalTaxPaid: prev.rta.totalTaxPaid + current.amount.rta.totalTaxPaid,
        price: 0,
        amount: 0,
        discount: 0,
        netAmount: 0,
      },
    };
  },
};

export interface PaymentState {
  isLoading: boolean;
  StudentCourse?: StudentCourse;
  user?: User;
  course?: Course;
  selectedMilestones?: any;
  summary: Amounts;
  stagePayment: {
    milestones: any[];
    addon: any[];
    summary: Amounts;
  };
  paymentPlan: paymentPlans;
  modeOfPayment: paymentModes;
  courseType?: string;
  promotion?: any;
  onlinePaymentInfo?: any;
  onlinePaymentStatus?: OnlinePaymentStatus;
  milestones: Array<CourseMilestone & { amount: Amounts }>;
  fees?: Dict<StudentCourseAmounts & { name: string; code: string }>;
  feeDetails?: StudentCourseAmounts & { name: string; code: string };
  errorMessage?: string;
  selectedFee?: any;
  paymentOptionStatus: boolean;
  getAddonData?: string;
  currectAddOn?: string;
}

// initial state
const initialState: PaymentState = {
  isLoading: false,
  StudentCourse: undefined,
  user: undefined,
  course: undefined,
  summary: summaryMapReduce.initial,
  stagePayment: {
    milestones: [],
    addon: [],
    summary: summaryMapReduce.initial,
  },
  paymentPlan: paymentPlans.fullPayment,
  modeOfPayment: paymentModes.online,
  courseType: undefined,
  promotion: undefined,
  onlinePaymentStatus: undefined,
  milestones: [],
  paymentOptionStatus: false,
  currectAddOn: undefined,
};

const formatCourse = (course?: StudentCourse) => {
  if (!course || !course.milestones) {
    return course;
  }
  const { milestones, stages } = course;
  // const pendingStages = stages.filter((stage) => stage.paid === false || !stage.amount.paymentStatus );
  const pendingStages = stages.filter((stage) => !stage.paid);

  const unPaidTheoryTest = pendingStages.find(
    (st: any) => !st.paid && st.stage.useStageName === true
  );

  const stageIds = pendingStages.map((a) => a.stage._id);
  const remainingMilestones: CourseMilestone[] = [];
  milestones.forEach((ml: CourseMilestone) => {
    const { instanceIds, name } = ml;
    if (stageIds.some((r) => instanceIds.indexOf(r) >= 0)) {
      if (
        unPaidTheoryTest &&
        instanceIds.includes(unPaidTheoryTest.stage._id)
      ) {
        ml.name = unPaidTheoryTest.stage.name;
      }
      remainingMilestones.push(ml);
    }
  });
  return {
    ...course,
    milestones: remainingMilestones.map((ml: CourseMilestone) => {
      return {
        ...ml,
        stages: course.stages
          .filter(
            (st) =>
              !st.paid &&
              ml.instanceIds.find((stId: string) => st.stage._id === stId)
          )
          .filter((sta: any) => sta !== undefined),
      };
    }),
  };
};

const getSummaryFromMilestones = (milestones: any[]) => {
  if (!milestones.length) {
    return summaryMapReduce.initial;
  }
  return milestones.reduce(summaryMapReduce.reduce, summaryMapReduce.initial);
};

const getSummaryFromAddOn = (addOn: any, milestones: any[]) => {
  if (!addOn) {
    if (!milestones.length) {
      return summaryMapReduce.initial;
    } else {
      return milestones.reduce(
        summaryMapReduce.reduce,
        summaryMapReduce.initial
      );
    }
  }

  return {
    amountRemaining: 0,
    noTaxableAmount: 0,
    totalAmountWithDiscount: addOn.totalAmount,
    totalAmountWithTax: addOn.totalAmountWithTax,
    roundOff: 0,
    taxableAmount: 0,
    totalAmount: addOn.totalAmount,
    totalAmountPaid: addOn.totalAmountWithTax,
    totalDiscount: 0,
    totalTax: addOn.totalTax,
    totalTaxPaid: addOn.totalTax,
    processigFee: 0,
    eds: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: addOn.eds.totalAmount,
      totalAmountPaid: addOn.eds.totalAmountWithTax,
      totalDiscount: 0,
      totalTax: addOn.eds.totalTax,
      totalTaxPaid: addOn.eds.totalTax,
      price: 0,
      amount: 0,
      discount: 0,
      netAmount: 0,
    },
    rta: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: addOn.rta.totalAmount,
      totalAmountPaid: addOn.rta.totalAmount,
      totalDiscount: 0,
      totalTax: addOn.rta.totalTax,
      totalTaxPaid: addOn.rta.totalTax,
      price: 0,
      amount: 0,
      discount: 0,
      netAmount: 0,
    },
  };
};

// reducer
export default (state = initialState, action: any): PaymentState => {
  switch (action.type) {
    // courses
    case types.GET_COURSES_REQUEST:
      return { ...state, isLoading: true, onlinePaymentInfo: undefined };
    case types.GET_COURSES_SUCCESS:
      const course = formatCourse(action.data.course);
      if (!course) {
        return {
          ...state,
          isLoading: false,
        };
      }

      const { milestones } = course;

      milestones.sort((a: any, b: any) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount?.promotions && course.amount?.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = milestones.length ? [milestones[0]] : [];
      const nextIndex = milestonesForStage.length;

      for (let i = nextIndex; i < milestones.length; i = i + 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount?.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      return {
        ...state,
        isLoading: false,
        summary,
        StudentCourse: course,
        course: course.course,
        courseType: course.courseType?.name?.en,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          addon: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        paymentPlan: paymentPlans.stagePayment,
        onlinePaymentInfo: undefined,
        milestones: course.milestones,
        onlinePaymentStatus: undefined,
      };

    case types.GET_COURSES_ERROR:
      return { ...state, isLoading: false };

    // milestones

    case types.SELECT_MILESTONE: {
      if (state.paymentPlan !== paymentPlans.stagePayment) {
        return state;
      }
      const { milestone, selected } = action.data;
      const { milestones: allMilestones } = state;
      let currentMilestones: Array<CourseMilestone & { amount: Amounts }> = [];
      // let { milestones } = state;
      // console.log(milestones);

      if (selected) {
        // milestones.push(milestone);
        currentMilestones = allMilestones.filter(
          (cm: any) => cm.level <= milestone.level
        );
      } else {
        currentMilestones = allMilestones.filter(
          (cm: any) => cm.level < milestone.level
        );
      }
      const nextMlIndex = currentMilestones.length;

      for (let i = nextMlIndex; i < allMilestones.length; i = i + 1) {
        const courseMilestone = allMilestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        currentMilestones.push(courseMilestone);
      }
      currentMilestones = currentMilestones.sort(
        (a: any, b: any) => a.level - b.level
      );
      const summaryValue = getSummaryFromMilestones(currentMilestones);
      return {
        ...state,
        summary: summaryValue,
        stagePayment: {
          ...state.stagePayment,
          milestones: currentMilestones,
          summary: summaryValue,
        },
        onlinePaymentInfo: undefined,
      };
    }

    // add on
    case types.SELECT_ADDON: {
      const { addOn, milestone } = action.data;
      const summaryValue = getSummaryFromAddOn(addOn, milestone);
      return {
        ...state,
        summary: summaryValue,
        stagePayment: {
          ...state.stagePayment,
          summary: summaryValue,
        },
        onlinePaymentInfo: undefined,
        currectAddOn: addOn,
      };
    }

    // payment
    case types.PAYMENT_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        onlinePaymentInfo: undefined,
        onlinePaymentStatus: OnlinePaymentStatus.initiated,
      };
    case types.PAYMENT_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,

        onlinePaymentInfo: action.data,
        onlinePaymentStatus: OnlinePaymentStatus.processing,
      };
    case types.PAYMENT_INITIATE_ERROR:
      return {
        ...state,
        isLoading: false,
        onlinePaymentStatus: undefined,
      };
    // payment
    case types.VERIFY_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        onlinePaymentStatus: OnlinePaymentStatus.verifying,
        onlinePaymentInfo: undefined,
      };
    case types.CHANGE_PAYMENT_METHOD: {
      const mode = action.data;
      if (mode === state.modeOfPayment) {
        return state;
      }
      return {
        ...state,
        modeOfPayment: mode,
        onlinePaymentInfo: undefined,
      };
    }
    case types.VERIFY_PAYMENT_SUCCESS:
      return { ...initialState };
    // return {
    //   ...state,
    //   isLoading: false,
    //   StudentCourse: undefined,
    //   onlinePaymentStatus: OnlinePaymentStatus.completed,
    //   onlinePaymentInfo: undefined,
    // };
    case types.VERIFY_PAYMENT_FAILED:
      return {
        ...state,
        isLoading: false,

        onlinePaymentInfo: undefined,
        onlinePaymentStatus: OnlinePaymentStatus.failed,
      };
    case types.VERIFY_PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        onlinePaymentStatus: OnlinePaymentStatus.failed,
        onlinePaymentInfo: undefined,
      };

    case types.GET_PAYMENT_REQUEST:
      return { ...state, isLoading: true, fees: undefined };
    case types.GET_PAYMENT_SUCCESS:
      return {
        ...state,
        fees: action.data,
        isLoading: false,
      };

    case types.GET_PAYMENT_ERROR:
      return { ...state, isLoading: false };

    case types.GET_PAYMENT_DETAILS_REQUEST:
      return { ...state, isLoading: true, feeDetails: undefined };
    case types.GET_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        feeDetails: action.data,
        isLoading: false,
      };
    case types.GET_PAYMENT_DETAILS_ERROR:
      return { ...state, isLoading: false, errorMessage: action.data };

    case types.ADDITIONAL_PAYMENT_INITIATE_REQUEST:
      return {
        ...state,
        onlinePaymentInfo: undefined,
        onlinePaymentStatus: OnlinePaymentStatus.initiated,
        isLoading: true,
      };

    case types.ADDITIONAL_PAYMENT_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,

        onlinePaymentInfo: action.data,
        onlinePaymentStatus: OnlinePaymentStatus.processing,
      };
    case types.ADDITIONAL_PAYMENT_INITIATE_ERROR:
      return {
        ...state,
        isLoading: false,
        onlinePaymentInfo: undefined,
      };

    case commonTypes.RESET_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  getCourse: (
    force: boolean,
    preApplyPromoCode: boolean,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.GET_COURSES_REQUEST });
    try {
      const response = await api.user.getStudentCourse();
      dispatch({
        type: types.GET_COURSES_SUCCESS,
        data: response.data,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.GET_COURSES_ERROR });
      serializeAndShowFormErrors(error);
      throw error;
    }
  },

  changePaymentPlan: (plan: string) => (dispatch: Dispatch) => {
    dispatch({
      type: types.CHANGE_PAYMENT_PLAN,
      data: plan,
    });
  },
  changePaymentMethod: (mode: paymentModes) => (dispatch: Dispatch) => {
    dispatch({
      type: types.CHANGE_PAYMENT_METHOD,
      data: mode,
    });
  },
  selectMilestone: (milestone: any, selected: boolean) => (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: types.SELECT_MILESTONE,
      data: { milestone, selected },
    });
  },

  selectAddOnFee: (addOn: any, milestone: any) => (dispatch: Dispatch) => {
    dispatch({
      type: types.SELECT_ADDON,
      data: { addOn, milestone },
    });
  },

  initiatePayment: (
    milestones: any,
    totalAmount: number,
    courseType: string,
    promotion: any,
    retryPayment?: boolean
  ) => async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const isOnlinePayment: boolean =
      state.payment.modeOfPayment === paymentModes.online;
    dispatch({ type: types.PAYMENT_INITIATE_REQUEST });
    try {
      const data = {
        courseType,
        milestones,
        amount: totalAmount,
        promotionId: promotion != null ? promotion._id : null,
        isOnlinePayment,
        redirectUrl: getHost() + RouteKeys.PaymentCallback,
        retryPayment,
        platform: 'web',
      };
      const response = await api.registration.initiatePayment(data);
      dispatch({ type: types.PAYMENT_INITIATE_SUCCESS, data: response.data });
      // toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.PAYMENT_INITIATE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      throw error;
    }
  },
  verifyPayment: (
    paymentReferenceData: PaymentReferenceInfo,
    retryPayment: boolean,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.VERIFY_PAYMENT_REQUEST });
    try {
      const response = await api.payment.verifyPayment(
        paymentReferenceData.ref as string,
        retryPayment
      );
      if (response && response.data && response.data.isSuccess) {
        dispatch({ type: types.VERIFY_PAYMENT_SUCCESS, data: response.data });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success('Payment Success', 'Payment completed successfully');
      } else if (
        response.data.reponseText === paymentErrorCodes.threeDSErrorOne ||
        response.data.reponseText === paymentErrorCodes.threeDSErrorTwo
      ) {
        toastr.error('Payment Error', paymentErrors.THREE_DS_NOT_AUTHENTICATED);
        dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      } else if (response.data.reponseText === paymentErrorCodes.authError) {
        toastr.error('Payment Error', paymentErrors.AUTHORISATION_FAILED);
        dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      } else {
        toastr.error('Payment Error', response.data.reponseText);
        dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      }
    } catch (error) {
      dispatch({ type: types.VERIFY_PAYMENT_ERROR });
    }
  },

  getAdditionalFees: (onSuccess?: () => void) => async (dispatch: Dispatch) => {
    dispatch({ type: types.GET_PAYMENT_REQUEST });
    try {
      const response = await api.payment.getAdditionalFees();
      dispatch({
        type: types.GET_PAYMENT_SUCCESS,
        data: response.data,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      // dispatch({ type: types.GET_PAYMENT_ERROR });
      // toastr.error('', error.response.data.message);
      // serializeAndShowFormErrors(error);
      throw error;
    }
  },

  getRTAAdditionalFees: (
    type: string,
    userId: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.GET_PAYMENT_REQUEST });
    try {
      const response = await api.payment.getAdditionalFeeDetails(type, userId);
      response.data.code = response.data.code.toLowerCase();
      const fees = {
        fridaySaturdayRTATestFee: response.data,
      };

      dispatch({
        type: types.GET_PAYMENT_SUCCESS,
        data: fees,
      });
      // const addon = response.data;
      // const milestone = '';
      // dispatch({
      //   type: types.SELECT_ADDON,
      //   data: { addon, milestone },
      // });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      // dispatch({ type: types.GET_PAYMENT_ERROR });
      // toastr.error('', error.response.data.message);
      // serializeAndShowFormErrors(error);
      throw error;
    }
  },

  getAdditionalFeeDetails: (
    type: string,
    userId: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.GET_PAYMENT_DETAILS_REQUEST });
    try {
      const response = await api.payment.getAdditionalFeeDetails(type, userId);
      dispatch({
        type: types.GET_PAYMENT_DETAILS_SUCCESS,
        data: response.data,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      let errorMessage = 'Please try again!';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      dispatch({ type: types.GET_PAYMENT_DETAILS_ERROR, data: errorMessage });
      toastr.error('', error.response.data.message);
      throw error;
    }
  },

  initiateAdditionalFeePayment: (
    feeDetails: any,
    totalAmount: number,
    retryPayment?: boolean,
    type?: string
  ) => async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const isOnlinePayment: boolean =
      state.payment.modeOfPayment === paymentModes.online;

    dispatch({ type: types.ADDITIONAL_PAYMENT_INITIATE_REQUEST });
    try {
      const data = {
        feeDetails,
        amount: totalAmount,
        isOnlinePayment,
        redirectUrl: getHost() + RouteKeys.PaymentCallback,
        retryPayment,
        platform: 'web',
        type,
      };
      const response = await api.payment.initiatePayment(data);
      dispatch({
        type: types.ADDITIONAL_PAYMENT_INITIATE_SUCCESS,
        data: {
          ...response.data,
          amount: data.amount,
        },
      });
      // toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.ADDITIONAL_PAYMENT_INITIATE_ERROR });
      toastr.error('', error.response.data.message);
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      throw error;
    }
  },

  collectAdditionalFees: (
    paymentReferenceData: any,
    userId: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.VERIFY_PAYMENT_REQUEST });
    try {
      const response = await api.payment.verifyAdditionalFees(
        paymentReferenceData,
        userId
      );

      if (response && response?.data && response?.data?.isSuccess) {
        dispatch({ type: types.VERIFY_PAYMENT_SUCCESS, data: response.data });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success('Payment Success', 'Payment completed successfully');
      } else if (
        response?.data?.responseText === paymentErrorCodes.threeDSErrorOne ||
        response?.data?.responseText === paymentErrorCodes.threeDSErrorTwo
      ) {
        toastr.error('Payment Error', paymentErrors.THREE_DS_NOT_AUTHENTICATED);
        dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      } else if (response?.data?.responseText === paymentErrorCodes.authError) {
        toastr.error('Payment Error', paymentErrors.AUTHORISATION_FAILED);
        dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      } else {
        toastr.error('Payment Error', response.data.responseText);
        dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      }
    } catch (error) {
      dispatch({ type: types.VERIFY_PAYMENT_ERROR });
    }
  },
};

export function serializeAndShowFormErrors(error: any) {
  // console.log(error);
  if (error.response && error.response.data && error.response.data.errors) {
    const { errors } = error.response.data;
    Object.keys(errors).forEach((e) => {
      // console.log('error: ', e, errors[e].message);
      toastr.error(errors[e].message, '');
    });
    return;
  }
  toastr.error('Error', 'Invalid Data in Form!');
}
