import React, { Component } from 'react';

import './PaymentPlanOption.scss';

interface CustomProps {
  selected: boolean;
  onSelect?: () => void;
  id: string;
  name: string;
  description?: string;
  totalAmount?: number;
  totalRtaAmount?: number;
  totalEdsAmount?: number;
  showTooltip?: boolean;
  primary?: boolean;
}
class PaymentPlanOption extends Component<CustomProps> {
  renderInput() {
    return (
      <input
        type="radio"
        id={this.props.id}
        checked={this.props.selected}
        onClick={this.props.onSelect}
        onChange={() => {
          return;
        }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderLabelIcon() {
    return (
      <div className="PaymentPlanRadio__round">
        <span className="circle-fill">
          <i className="icon-tick" />
        </span>
      </div>
    );
  }

  renderLabelText() {
    return (
      <div className="PaymentPlanRadio__text-left">
        <div className="PaymentPlanRadio__maintext">{this.props.name}</div>
        <div
          className={`PaymentPlanRadio__subtext PaymentPlanRadio__subtext${
            this.props.primary ? '--primary' : ''
          }`}
        >
          {this.props.description}
        </div>
      </div>
    );
  }

  renderTooltipRtaAmount() {
    return (
      <span className="PaymentPlan__tooltip-item">
        RTA Fee -{' '}
        <span className="PaymentPlan__tooltip-price">
          AED {(this.props.totalRtaAmount || 0).toFixed(2)}
        </span>
      </span>
    );
  }

  renderTooltipEdsAmount() {
    return (
      <span className="PaymentPlan__tooltip-item">
        EDC Fee -{' '}
        <span className="PaymentPlan__tooltip-price">
          AED {(this.props.totalEdsAmount || 0).toFixed(2)}
        </span>
      </span>
    );
  }

  renderAmountTooltip() {
    return (
      <div className="PaymentPlan__tooltip Show">
        {this.renderTooltipRtaAmount()}
        {this.renderLabelAmount()}
      </div>
    );
  }

  renderLabelAmount() {
    return (
      <div className="PaymentPlanRadio__text-right">
        <div className="PaymentPlan__price">AED {this.props.totalAmount}</div>
        {this.props.showTooltip && this.renderAmountTooltip()}
      </div>
    );
  }

  renderLabel() {
    return (
      <label className="PaymentPlanRadio__label" htmlFor={this.props.id}>
        {this.renderLabelIcon()}
        <div className="PaymentPlanRadio__text">
          {this.renderLabelText()}
          {this.renderLabelAmount()}
        </div>
      </label>
    );
  }

  render() {
    return (
      <div className="PaymentPlanRadio">
        {this.renderInput()}
        {this.renderLabel()}
      </div>
    );
  }
}

// PaymentPlanOption.propTypes = {
//   selected: PropTypes.bool.isRequired,
//   onSelect: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   totalAmount: PropTypes.number.isRequired,
//   totalRtaAmount: PropTypes.number.isRequired,
//   totalEdsAmount: PropTypes.number.isRequired,
//   showTooltip: PropTypes.bool,
//   primary: PropTypes.bool,
// };

export default PaymentPlanOption;
