import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import '../Input.scss';
import './RadioSelectInput.scss';

interface CustomProps {
  default?: string | boolean;
  name: string;
  label?: string;
  error?: string;
  gap?: 'sm' | 'md' | 'lg';
  type?: 'text' | 'boolean';
  options: Array<{ [key: string]: any }>;
  optionLabel?: string;
  optionValue?: string;
  key?: string;
  onChange: (values: string | boolean) => void;
}

interface CustomState {
  value?: string | boolean;
}

class RadioSelectInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    label: '',
    error: '',
    gap: 'md',
    type: 'text',
    optionLabel: 'label',
    optionValue: 'value',
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
    }
  }

  handleChange(event: { target: { value: any } }) {
    const value =
      this.props.type === 'boolean'
        ? event.target.value === 'true'
          ? true
          : false
        : event.target.value;
    this.setState({ value });
    this.props.onChange(value);
  }

  render() {
    const {
      name,
      label,
      options,
      optionLabel,
      optionValue,
      error,
      gap,
      key,
    } = this.props;
    return (
      <div className={`Input Input--gap-${gap}`}>
        {label && (
          <label htmlFor={name} className="Input__label Input__label--full">
            {label}
          </label>
        )}

        {options.map((option) => {
          return (
            <div className="RadioSelect" key={key || option[optionValue || '']}>
              <input
                type="radio"
                id={`${name}-${option[optionValue || '']}`}
                value={option[optionValue || '']}
                name={name}
                checked={option[optionValue || ''] === this.state.value}
                onChange={this.handleChange}
              />
              <label
                className="RadioSelect__label"
                htmlFor={`${name}-${option[optionValue || '']}`}
              >
                {option[optionLabel || '']}
              </label>
            </div>
          );
        })}

        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// RadioSelectInput.propTypes = {
//   default: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//   name: PropTypes.string.isRequired,
//   label: PropTypes.string,
//   error: PropTypes.string,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   type: PropTypes.oneOf(['text', 'boolean']),
//   options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   optionLabel: PropTypes.string,
//   optionValue: PropTypes.string,
//   onChange: PropTypes.func,
// };

// RadioSelectInput.defaultProps = {
//   default: '',
//   label: '',
//   error: '',
//   gap: 'md',
//   type: 'text',
//   onChange: () => {},
//   optionLabel: 'label',
//   optionValue: 'value',
// };

export default RadioSelectInput;
