import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Button from '../../components/button';
import ThankYouImage from '../../assets/images/thankyou-img.png';
import { RouteKeys } from '../route-keys';
// actions
import { actions as authUserActions } from '../../store/reducers/authUser';
import { actions as registerActions } from '../../store/reducers/registration';
// import { useGTagConversionTracker } from '../../hooks/gtag';

const ThankYouPage: React.SFC = () => {
  
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchProfile = (onSuccess?: () => void) => {
    dispatch(registerActions.resetStudentData());
    if(onSuccess){
      onSuccess()
    }
  }

  const goBackHome = async () => {
    setLoading(true);
    await fetchProfile(() => {
      history.replace(RouteKeys.Dashboard);
      setLoading(false);
    });
  };

  // eslint-disable-next-line class-methods-use-this
  const renderImage = () => {
    return (
      <div className="Thankyou-img">
        <img src={ThankYouImage} className="img-fluid" alt="thankyou" />
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderHeader = () => {
    return (
      <h3 className="font-weight-bold text-uppercase text-primary pt-5 pb-2">
        Thank you
      </h3>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderDescription = () => {
    return (
      <p className="font-lg text-muted mb-5">
        <span className="mr-1 mb-1 d-sm-block">
          For registering with Excellence Driving Center.
        </span>
        We will contact you soon once our executives verified your details.
      </p>
    );
  };

  const renderBackButton = () => {
    return (
      <Button
        type="button"
        className="Button Button--md"
        onClick={goBackHome}
        disabled={loading}
      >
        {loading ? 'Please wait' : 'Back to Dashboard'}
      </Button>
    );
  };

  const renderBody = () => {
    return (
      <div className="Thankyou text-center py-5 my-4">
        <div className="container">
          {renderImage()}
          {renderHeader()}
          {renderDescription()}
          {renderBackButton()}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <Header /> */}
      {renderBody()}
      {/* <Footer /> */}
    </>
  );
};

export default ThankYouPage;
