import { trackGtagConversion, GtagSendTo } from './gtag';
import { trackFbLead } from './fbq';

export const trackPurchase = (
  amount: number,
  orderId: string,
  promoCode: string,
  paymentStage: string
) => {
  trackFbLead();
  trackGtagConversion(
    {
      allow_custom_scripts: true,
      value: `${amount || 0}`,
      transaction_id: orderId,
      u2: promoCode,
      u3: paymentStage,
      send_to: GtagSendTo.Purchase,
    },
    'purchase'
  );
  trackGtagConversion(
    {
      send_to: GtagSendTo.Marketing,
    },
    'conversion'
  );
};
