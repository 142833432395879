import { toastr } from 'react-redux-toastr';

export default function(error) {
  // console.log('ERROR: ', error.response);
  const data = error.response ? error.response.data : {};

  switch (data.statusCode) {
    case 400:
      errorHandler400(data);
      break;
    case 401:
      errorHandler401(data);
      break;
    case 422:
      errorHandler422(data);
      break;
    case 500:
      errorHandler500(data);
      break;
    default:
      toastr.error('Error', 'Invalid Data in Form!');
  }
}

function errorHandler400(data) {
  if (!data.errors || !Object.keys(data.errors).length) {
    toastr.error('Error', data.message || 'Invalid Data in Form!');
    return;
  }
  let { errors } = data;
  if (errors.errors) {
    errors = errors.errors;
  }
  Object.keys(errors).forEach((e) => {
    // console.log('error: ', e, errors[e].message);
    toastr.error(errors[e].message);
  });
}

function errorHandler401(data) {
  toastr.error('Error', 'Invalid Data in Form!');
}

function errorHandler422(data) {
  if (data.message) {
    toastr.error('Error', data.message);
    return;
  }
  toastr.error('Error', 'Invalid Data in Form!');
}

function errorHandler500(data) {
  toastr.error('Internal Server Error');
}
